
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChildren,
  QueryList,
  DestroyRef,
} from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators, UntypedFormBuilder, AbstractControl } from '@angular/forms';
import { CompanyUser, CompanyUserPagedRequest } from 'app/models/CompanyUser';
import { UserViewModel } from 'app/models/UserViewModel';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { State, getCompanyUsers, getDashboardRole, getGSettingsGlobalSettingsModel, getSelectedClient } from 'app/redux/reducers';
import { GetCompanyUsersAction, ResetUserListAction } from 'app/redux/actions/user';
import { UserStatus } from 'app/shared/enums';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { skipWhile, map } from 'rxjs/operators';
import { UserCompanyRole } from '@cartwheel/web-components';
import { UserSettings } from 'app/models/user-settings';
import { CartwheelSelectOptions, SuccessStatus } from '@cartwheel/web-components';
import { PATTERNS } from 'app/shared/consts';

@Component({
  selector: 'app-add-users',
  templateUrl: './add-users.component.html',
  styleUrls: ['./add-users.component.scss']
})

export class AddUsersComponent implements OnInit, OnChanges {
  @Input() isMobile: Boolean;
  @Input() assignedUsers: UserViewModel[];
  @Input() newClient: Boolean = false;
  @Output() userFormEmitter: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();
  @ViewChildren(MatAutocompleteTrigger) autocompletes: QueryList<MatAutocompleteTrigger>

  public currentRole$: Observable<UserCompanyRole>;
  public allowOvertimeRate$: Observable<boolean>;
  public usersList: CompanyUser[] = [];
  public fullUsersList: CompanyUser[] = [];
  public usersForm: UntypedFormGroup;
  public subscription: Subscription;
  public status = UserStatus;
  public currentPage: number = 0;
  public perPage: number = 5;
  public successStatus = SuccessStatus;
  public userListOptions: CartwheelSelectOptions<string> = [];
  public userOptions: [];

  constructor(private store: Store<State>, private fb: UntypedFormBuilder, private destroyRef: DestroyRef) { }

  ngOnInit() {
    this.store.dispatch(ResetUserListAction());
    if (!this.usersForm) {
      this.usersForm = this.updateUserFormArray([]);
    }

    this.currentRole$ = this.store.select(getDashboardRole).pipe(skipWhile(cr => !cr));

    this.allowOvertimeRate$ = this.store.select(getGSettingsGlobalSettingsModel)
      .pipe(map((globalSettings: UserSettings) => globalSettings.companySettings?.allowOvertimeRate));

    this.currentRole$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((role) => {
        this.store.dispatch(GetCompanyUsersAction(new CompanyUserPagedRequest()));
      });

    this.store.select(getSelectedClient)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.currentPage = 0;
      });

    this.store.select(getCompanyUsers)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((users) => {
        this.fullUsersList = [...this.fullUsersList, ...users.data]
          .sort((a, b) => (a.firstName + a.lastName).localeCompare(b.firstName + b.lastName));
        if (users.totalPages > users.page) {
          this.store.dispatch(GetCompanyUsersAction({page: users.page  + 1}));
        } else {
          const assignedUsers = (this.assignedUsers) ? this.assignedUsers : [];
          this.usersList = this.fullUsersList.filter((u) => assignedUsers.findIndex((au) => au.userId === u.userId) < 0);
          this.userListOptions = this.fullUsersList.map(u => ({
            label: `${u.firstName} ${u.lastName}${
              u.status !== this.status.Confirmed ?
                ` (UNCONFIRMED)` :
                ''}`,
            value: u.userName
          }));
          if (this.newClient) {
            this.usersForm = new UntypedFormGroup({
              users: new UntypedFormArray([])
            });
            this.subscription = this.usersForm.valueChanges
              .pipe(takeUntilDestroyed(this.destroyRef))
              .subscribe(() => this.updateForm());
            this.addNewBlankUser();
          }
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.assignedUsers && changes.assignedUsers.currentValue) {
      this.userListOptions = this.fullUsersList?.map(u => ({ 
        label: `${u.firstName} ${u.lastName}${
          u.status !== this.status.Confirmed ? 
            ` (UNCONFIRMED)` : 
            ''}`,
        value: u.userName,
        hidden: this.assignedUsers.findIndex((au) => au.userId === u.userId) > -1
      }));
      this.usersForm = this.updateUserFormArray(this.assignedUsers);
    }
  }

  get userControls() {
    return (this.usersForm.get('users') as UntypedFormArray)
      .controls
      .slice(this.currentPage * this.perPage, this.currentPage * this.perPage + this.perPage);
  }

  get selectedUsers(): UntypedFormArray {
    return this.usersForm.get('users') as UntypedFormArray;
  }

  private updateUserFormArray(users: UserViewModel[]): UntypedFormGroup {
    const formGroups = users.map(u => new UntypedFormGroup({
      username: new UntypedFormControl(u.username, Validators.required),
      billedRate: new UntypedFormControl(u.billedRate, [Validators.pattern(PATTERNS.DecimalWithMax9999)]),
      otBilledRate: new UntypedFormControl(u.otBilledRate, [Validators.pattern(PATTERNS.DecimalWithMax9999)]),
      editable: new UntypedFormControl(false)
    }));

    const userForm = new UntypedFormGroup({
      users: new UntypedFormArray(formGroups)
    });

    this.subscription = userForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.updateForm());
    return userForm;
  }

  public showAutoComplete(userControl: AbstractControl, i: number) {
    const user = userControl as UntypedFormGroup;
    if (user.get('editable').value) {
      const autocomplete = this.autocompletes.find((item, index) => index === i);
      (autocomplete.panelOpen) ? autocomplete.closePanel() : autocomplete.openPanel();
    }
  }

  public addNewBlankUser() {
    if (this.usersList.length > 0 && this.usersForm.valid) {
      const fg = new UntypedFormGroup({
        username: new UntypedFormControl(null, Validators.required),
        billedRate: new UntypedFormControl(0, [Validators.pattern(PATTERNS.DecimalWithMax9999)]),
        otBilledRate: new UntypedFormControl(0, [Validators.pattern(PATTERNS.DecimalWithMax9999)]),
        editable: new UntypedFormControl(true)
      });
      (<UntypedFormArray>this.usersForm.get('users')).push(fg);
    }
  }

  public removeUser(index: number) {
    const users = (<UntypedFormArray>this.usersForm.get('users'))
    users.removeAt(index);
  }

  public updateForm() {
    const assignedUsers = <UntypedFormArray>this.usersForm.get('users');

    const usernames: string[] = assignedUsers.controls
      .filter((fg) => fg.get('username').value)
      .map((fg) => fg.get('username').value);

    this.userListOptions = this.fullUsersList.map(u => ({ 
      label: `${u.firstName} ${u.lastName}${
        u.status !== this.status.Confirmed ? 
          ` (UNCONFIRMED)` : 
          ''}`,
      value: u.userName,
      hidden: usernames.findIndex((name) => name === u.userName) > -1
    }));

    if (this.usersForm.valid) {
      const users = assignedUsers.controls.map((userFormGroup) => {
        const companyUser = this.fullUsersList.find((user) => userFormGroup.get('username').value === user.userName)
        const userViewModel = new UserViewModel(companyUser);
        userViewModel.billedRate = userFormGroup.get('billedRate').value;
        userViewModel.username = companyUser.userName;
        userViewModel.otBilledRate = userFormGroup.get('otBilledRate').value
        return userViewModel;
      });

      this.subscription.unsubscribe(); // the next line changes a value which would continuously call updateForm()
      this.usersForm.setControl('assignedUsers', this.fb.array(users));
      this.subscription = this.usersForm.valueChanges
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => this.updateForm());

      this.usersForm.markAsDirty();
      this.userFormEmitter.emit(this.usersForm);
    } else {
      this.usersForm.markAsPristine();
    }
  }

  public get totalPages(): number {
    return Math.ceil((this.usersForm.get('users') as UntypedFormArray).controls.length / this.perPage);
  }

  public displayName(username: string): string {
    const user = this.fullUsersList.find(u => u.userName === username);
    return user ? user.firstName + " " + user.lastName : ''
  }

  public handleSelectedUser(event, userControl): void {
    userControl.get('username').setValue(event.option.value);
  }

  public prevPage(): void {
    if (this.currentPage === 0) return;
    this.currentPage -= 1;
  }

  public nextPage(): void {
    if (this.currentPage === this.totalPages - 1) return;
    this.currentPage += 1;
  }
}
