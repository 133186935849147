<form [formGroup]="approvalForm">
  <div formArrayName="approvers">
    @for (approver of approvers.controls; track 'approver' + $index) {
      <div class="grid pb-25 gap-y-20" [formGroupName]="$index">
        <div class="flex items-center gap-x-5">
          <label class="font-medium uppercase text-12 text-darkGray">LEVEL {{ $index + 1 }} APPROVER</label>
          @if (isAdmin) {
            <cartwheel-icon-button matIconName="delete" color="danger" size="sm" (onClick)="removeApprovers($index)" />
          }
        </div>

        @if (false) {
          <mat-radio-group formControlName="approvalType">
            <div class="grid grid-cols-2 gap-10">
              @for (approvalType of approvalTypesOptions; track 'approvalType' + approvalType.value) {
                <mat-radio-button class="form-label-value" color="primary" [value]="approvalType.value">
                  {{ approvalType.name }}
                </mat-radio-button>
              }                
            </div>
          </mat-radio-group>
        }

        @if (approvers?.controls[$index].get('approvalType').value === approvalType.Application) {
          <div class="clients">
            @for (application of applications; track 'application' + application.value) {
              <div class="pb-20 h-100">
                <cartwheel-image-button
                  [imgSrc]="application.image"
                  [status]="getLoadingStatus(application.value)"
                  (onClick)="handleClick(application.value, approvers?.controls[$index], $index)"
                />
              </div>
            }
          </div>
        }

        @if (approvers?.controls[$index].get('approvalType').value === approvalType.User) {
          <div>
            <cartwheel-chip-grid
              [placeholder]="approver.get('contactEmails').value.length < 1 ? 'Enter email address here...' : ''"
              [chipFormControl]="approver.get('contactEmails') | formControlType"
              [disabled]="!isAdmin"
            />
            @if (approver.get('contactEmails').errors?.required) {
              <span class="text-danger text-12">Please enter email address</span>
            } @else if (approver.get('contactEmails').errors?.maxlength) {
              <span class="text-danger text-12">You can add 1 approver only</span>
            } @else if (approver.get('contactEmails').errors?.email) {
              <span class="text-danger text-12">Please enter valid email address</span>
            }
          </div>
        }

        @if (approvers?.controls[$index].get('approvalType').value === approvalType.User) {
          <div>
            <div class="pb-8">
              <label class="font-medium uppercase text-12 text-darkGray">Which activity report would you like to send?</label>
            </div>

            <mat-radio-group formControlName="reportTypes">
              <div class="grid grid-cols-2">
                @for (reportType of reportTypesOptions; track 'reportType' + reportType.value) {
                  <mat-radio-button 
                    color="primary"
                    [value]="reportType.value">
                    {{ reportType.name }}
                  </mat-radio-button>
                }
              </div>
            </mat-radio-group>

            <div class="pb-8">
              <label class="font-medium uppercase text-12 text-darkGray">File Type</label>
            </div>

            <mat-radio-group formControlName="fileType">
              <div class="grid grid-cols-2">
                @for (fileType of fileTypesOptions; track 'file-type' + fileType.value) {
                  <mat-radio-button color="primary" [value]="fileType.value">
                    {{ fileType.name }}
                  </mat-radio-button>
                }
              </div>
            </mat-radio-group>
            <div class="pb-8">
              <label class="font-medium uppercase text-12 text-darkGray">Send report as a</label>
            </div>

            <mat-radio-group formControlName="notificationType">
              <div class="grid grid-cols-2">
                @for (approvalNotification of approvalNotifcations; track 'notification' + approvalNotification.value) {
                  <mat-radio-button class="form-label-value" color="primary" [value]="approvalNotification.value">
                    {{ approvalNotification.name }}
                  </mat-radio-button>
                }
              </div>
            </mat-radio-group>
          </div>
        }
      </div>
    }

    @if (isAdmin) {
      <div class="flex justify-center py-5 tablet:justify-normal">
        <cartwheel-button
          class="max-w-200"
          label="Add New Approver"
          type="outline-button"
          color="middleGray"
          size="sm"
          (onClick)="addNewBlankApprover()"
        />
      </div>
    } @else {
      <div class="hidden pb-10 tablet:block">
        <hr />
      </div>
    }
  </div>
</form>
