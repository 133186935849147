<div class="relative text-center border-dotted timesheet-uploader p-15 rounded-xl min-h-200 border-3 border-lightGray">
  @if (status === SuccessStatus.IsLoading) {
    <div class="absolute -translate-x-1/2 -translate-y-1/2 loading-bar top-1/2 left-1/2">
      <div class="mb-5 font-bold whitespace-nowrap">{{statusMessage}}</div>
      <mat-progress-bar mode="indeterminate" />
    </div>
  }
  <div [class.opacity-10]="status === SuccessStatus.IsLoading">
    <div appFileUploader (fileDropped)="fileDropped($event)">
      <input
        #fileInputRef
        type="file"
        id="fileInputRef"
        class="cursor-pointer opacity-0 absolute z-[2] w-full h-full top-0 left-0"
        [accept]="allowedFileType"
        (change)="onChange($event)"/>
      <div>
        <mat-icon class="text-[80px] w-auto h-auto mb-10 text-lightGray">cloud_upload</mat-icon>
      </div>
      <div class="absolute left-1/2 -translate-x-1/2 z-[3] cursor-pointer text-secondary font-bold hover:opacity-70" (click)="downloadTemplate()">
        Download the template
      </div>
      <label class="block mt-30 leading-18 text-middleGray" for="fileInputRef">
        <div>then Drag and Drop here</div>
        <div>Only XLSX files supported</div>
      </label>
    </div>
  </div>
</div>

@if (selectedFile && status !== SuccessStatus.IsLoading) {
  <div>
    <table class="w-full border-collapse text-12 mt-30 text-middleGray">
      <tr>
        <th class="w-1/5 pb-20 text-center border-b border-solid border-lightGray">Name</th>
        <th class="w-1/5 pb-20 text-center border-b border-solid border-lightGray">Imported By</th>
        <th class="w-1/5 pb-20 text-center border-b border-solid border-lightGray">Import Date</th>
      </tr>
      <tr>
        <td class="p-5 text-center border-b border-solid opacity-65 border-lightGray h-28">{{selectedFile.name}}</td>
        <td class="p-5 text-center border-b border-solid opacity-65 border-lightGray h-28">{{client.clientName}}</td>
        <td class="p-5 text-center border-b border-solid opacity-65 border-lightGray h-28">{{selectedFile.lastModifiedDate | dateFormat: 'MM/DD/YYYY hh:mm'}}</td>
      </tr>
    </table>
  </div>
}