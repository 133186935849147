
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  HostListener,
  Input,
  OnChanges,
  DestroyRef,
  OnInit,
  SimpleChanges,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { AbstractControl, FormGroup, ReactiveFormsModule, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Client } from '../../../models/Client';
import { OAuthIdent } from '../../../models/OAuthIdent';
import { Approver, ApplicationAuthStatus, ApplicationLoadingStatus } from '../../../models/approver';
import {
  ApplicationTypes, ApprovalNotificationType,
  ApprovalType, FileType, ReportType, SuccessStatus, ExternalInvoicingSystems, Status
} from '../../../shared/enums';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { CartwheelButtonComponent, CartwheelChipGridComponent, CartwheelIconButtonComponent, CartwheelImageButtonComponent } from '@cartwheel/web-components';
import { MatRadioModule } from '@angular/material/radio';
import { State, getApproverComplete, getApproverAuthStatus, getApproverLoadingStatus } from '../../../redux/reducers';
import { TogglOpenDialogAction, HarvestLoginAction } from '../../../redux/actions/approver';
import { PATTERNS } from 'app/shared/consts';
import { emailListValidator } from 'app/shared/formvalidators';
import { PipesModule } from 'app/pipes/pipes.module';

@Component({
  standalone: true,
  selector: 'app-client-approvers',
  templateUrl: './client-approvers.component.html',
  styleUrls: ['./client-approvers.component.scss'],
  imports: [
    CartwheelIconButtonComponent, CartwheelImageButtonComponent, CartwheelChipGridComponent,
    CartwheelButtonComponent, MatRadioModule, PipesModule, ReactiveFormsModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientApproversComponent implements OnInit, OnChanges {
  @Input() approvalForm: UntypedFormGroup;
  @Input() isAdmin = true;
  @Input() isMobile: boolean;
  @Input() isEnterprise: boolean;
  @Input() selectedClient: Client;

  @Output() approvalFormEmitter: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();
  @Output() saveClient: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();

  harvestBtn = false;
  adpBtn = false;
  togglBtn = false;
  approverModel = {} as Approver;
  activeComponent = true;
  removable = true;
  addOnBlur = true;
  contactEmails = [];
  emailInputActive = false;
  approvalType = ApprovalType;
  // References the authentication popup window
  public authStatus$: Observable<ApplicationAuthStatus>;
  public authStatus: ApplicationAuthStatus;
  public loadingStatus$: Observable<ApplicationLoadingStatus>;
  public loadingStatus: ApplicationLoadingStatus;
  public currentApproverLevel: number;
  public approverArray: UntypedFormArray = null;
  public windowHandle: {};
  public componentActive = true;
  public providerLoading = false;
  public externalProviderName = '';
  public activeExternalInvoice = '';
  public separatorKeysCodes = [ENTER, COMMA];
  public NetworkConnectionStatus = SuccessStatus;

  applications = [
    {
      name: 'Harvest',
      image: 'assets/harvest.png',
      value: ApplicationTypes.Harvest
    },
    {
      name: 'ADP',
      image: 'assets/adp.png',
      value: ApplicationTypes.ADP
    },
    {
      name: 'Toggl',
      image: 'assets/toggl-nobackground.png',
      value: ApplicationTypes.Toggl
    }
  ];
  approvalTypesOptions = [
    {
      name: 'Application',
      value: ApprovalType.Application
    },
    {
      name: 'User',
      value: ApprovalType.User
    }
  ];
  reportTypesOptions = [
    {
      name: 'Overview',
      value: ReportType.Overview
    },
    {
      name: 'Detailed',
      value: ReportType.Activty
    }
  ];
  fileTypesOptions = [
    {
      name: 'Excel',
      value: FileType.Excel
    },
    {
      name: 'Pdf',
      value: FileType.PDF
    }
  ];
  approvalNotifcations = [
    {
      name: 'Approval',
      value: ApprovalNotificationType.Approval
    },
    {
      name: 'Notification',
      value: ApprovalNotificationType.Notify
    }
  ];

  constructor(private store: Store<State>, private ref: ChangeDetectorRef, private destroyRef: DestroyRef) { }

  ngOnInit() {
    if (!this.approvalForm) {
      this.approvalForm = this.updateApproverFormArray([]);
    }

    this.approvalForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: UntypedFormGroup) => {
        const approvers = res['approvers'];
        if (approvers) {
          this.approverArray = (approvers as UntypedFormArray);
        }
      });    
    this.authStatus$ = this.store.select(getApproverAuthStatus);
    this.authStatus$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(status => this.authStatus = status);

    this.loadingStatus$ = this.store.select(getApproverLoadingStatus);
    this.loadingStatus$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(status => this.loadingStatus = status);
  }

  get approvers(): UntypedFormArray {
    return this.approvalForm.get('approvers') as UntypedFormArray;
  }

  public getLoadingStatus(provider: ApplicationTypes): number {
    if (typeof this.loadingStatus[provider] === 'boolean') {
      return SuccessStatus.Enabled;
    }
    return this.loadingStatus[provider] as number;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedClient) {
      const selectedClient = <Client>changes.selectedClient.currentValue;
      if (selectedClient) {
        if (changes.selectedClient.currentValue && changes.selectedClient.previousValue) {
          const currentClientID = changes.selectedClient.currentValue.clientID;
          const previousClientID = changes.selectedClient.previousValue.clientID;
          // If new client is selected
          if (currentClientID && previousClientID !== currentClientID) {
            this.addApprovers(selectedClient.approvers);
          }
        }
        if (selectedClient.approvers) {
          if (selectedClient.approvers.length > 0) {
            this.addApprovers(selectedClient.approvers);
          }
        } else {
          this.approvalForm = this.updateApproverFormArray([]);
        }
      }
    }
  }

  private updateApproverFormArray(array): UntypedFormGroup {
    return new UntypedFormGroup({
      approvers: new UntypedFormArray(array)
    });
  }

  public onClick(event: MouseEvent, form: AbstractControl) {
    const target = <HTMLInputElement>event.target;
    if (form.get('emails').value) {
      target.blur();
      this.ref.markForCheck();
    };
    event.stopPropagation();
    event.preventDefault();
  }

  // Method is invoked when the user clicks on any of the 3 provider buttons,
  // Harvest, ADP, Toggl
  public handleClick(provider: ApplicationTypes, contentControl: AbstractControl, index: number) {
    const content = contentControl as UntypedFormGroup;
    if (this.loadingStatus[provider] === SuccessStatus.Disabled) {
      return;
    }

    if (this.isEnterprise) {
      content.get('appType').setValue(provider);
      return;
    }

    if (content.get('appType').value === provider) {
      content.get('appType').setValue(null);
      this.ref.markForCheck();
      return;
    }

    this.currentApproverLevel = index;
    this.subscribeToLoginCompletion(provider, content);

    if (provider === ApplicationTypes.Harvest) {
      this.store.dispatch(new HarvestLoginAction());
    } else if (provider === ApplicationTypes.Toggl) {
      this.store.dispatch(new TogglOpenDialogAction({
        name: ExternalInvoicingSystems[ExternalInvoicingSystems.Toggl],
        imageSrc: 'assets/toggl-nobackground.png'
      }));
    }
  }

  public subscribeToLoginCompletion(provider: ApplicationTypes, form: UntypedFormGroup) {
    const complete$ = this.store.select(getApproverComplete);

    const subScription = complete$.subscribe((complete) => {
      if (complete === true) {
        form.get('appType').setValue(provider);
        this.updateForm();
        this.ref.markForCheck();
        this.saveClient.emit();
      }
      subScription.unsubscribe();
    });
  }

  public checkBoxChange(type, form: UntypedFormControl, value) {
    const values = form.get(type).value;
    const index = values.indexOf(value);
    if (index === -1) {
      form.get(type).setValue(values.concat(value));
    } else {
      const newValues = values.slice(0, index).concat(values.slice(index + 1, values.length));
      form.get(type).setValue(newValues);
    }
  }

  public addNewBlankApprover() {
    const fg = new UntypedFormGroup({
      emails: new UntypedFormControl(null, [Validators.required, Validators.minLength(1)]),
      externalId: new UntypedFormControl(null),
      appType: new UntypedFormControl(null),
      notificationType: new UntypedFormControl(0),
      externalOauthInfo: new UntypedFormControl(new OAuthIdent({})),
      order: new UntypedFormControl((<UntypedFormArray>this.approvalForm.get('approvers')).length),
      fileType: new UntypedFormControl(0),
      reportTypes: new UntypedFormControl(0),
      // for mat chip
      contactEmails: new UntypedFormControl([], [Validators.required, Validators.minLength(1), Validators.maxLength(1), Validators.email])
    });

    (<UntypedFormArray>this.approvalForm.get('approvers')).push(fg);

    this.updateEmailsFromContactEmails(fg);

    const approvalType = new UntypedFormControl(ApprovalType.User);
    this.setVariableControls(approvalType, fg);
  }

  public removeApprovers(index: number) {
    (<UntypedFormArray>this.approvalForm.get('approvers')).removeAt(index);
    this.approvalForm.markAsDirty();
  }

  private setEmailControl(type: ApprovalType, emails: string) {
    if (type === ApprovalType.Application) {
      return new UntypedFormControl(null);
    }
    const fc = new UntypedFormControl(emails, [Validators.required]);
    fc.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.updateForm());
    return fc;
  }

  private setAppTypeControl(type: ApprovalType, appType: ApplicationTypes) {
    if (type === ApprovalType.User) {
      return new UntypedFormControl(appType);
    }

    const fc = new UntypedFormControl(appType, [Validators.required]);
    fc.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.updateForm());
    return fc;
  }

  private setFileType(type: ApprovalType, fileType: FileType) {
    if (type === ApprovalType.Application) {
      return new UntypedFormControl(fileType);
    }

    const fc = new UntypedFormControl(fileType, [Validators.required]);
    fc.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.updateForm());
    return fc;
  }

  private setReportType(type: ApprovalType, reportType: ReportType) {
    if (type === ApprovalType.Application) {
      return new UntypedFormControl(null);
    }

    const fc = new UntypedFormControl(reportType, [Validators.required]);
    return fc;
  }

  private setContactEmailControl(type: ApprovalType, contactEmails: string[]) {
    if (type === ApprovalType.Application) {
      return new UntypedFormControl(contactEmails);
    }

    const fc = new UntypedFormControl(contactEmails, [Validators.required, emailListValidator()]);
    fc.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.updateForm());
    return fc;
  }

  private setVariableControls(approvalTypeControl: UntypedFormControl, fg: UntypedFormGroup) {
    approvalTypeControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((type) => {
      fg.setControl('contactEmails', this.setContactEmailControl(type, fg.get('contactEmails').value));
      fg.setControl('emails', this.setEmailControl(type, fg.get('contactEmails').value.join(',')));
      fg.setControl('appType', this.setAppTypeControl(type, fg.get('appType').value));
    });

    fg.setControl('approvalType', approvalTypeControl);
  }

  private addApprovers(approvers: Array<Approver>) {
    const approvalForm = new Array<UntypedFormGroup>();
    approvers = approvers.filter((approver) => (
      approver.status !== Status.Deleted && approver.status !== Status.Inactive));

    for (let i = 0; i < approvers.length; i += 1) {
      let approverId;
      if (approvers[i].approverId) {
        approverId = new UntypedFormControl(approvers[i].approverId);
      }

      const externalId = new UntypedFormControl(approvers[i].externalId ? approvers[i].externalId : null);
      const notificationType = new UntypedFormControl({
        value: approvers[i].notificationType ? approvers[i].notificationType : ApprovalNotificationType.Approval,
        disabled: !this.isAdmin
      });

      const externalOauthInfo = new UntypedFormControl(
        approvers[i].externalOauthInfo ? approvers[i].externalOauthInfo : new OAuthIdent({})
      );
      const order = new UntypedFormControl(approvers[i].order ? approvers[i].order : approvalForm.length);
      let clientId;
      if (approvers[i].clientId) {
        clientId = new UntypedFormControl(approvers[i].clientId);
      }

      const approverType = approvers[i].approvalType;
      const formGroup = new UntypedFormGroup({
        emails: this.setEmailControl(approverType, approvers[i].emails),
        appType: this.setAppTypeControl(approverType, approvers[i].appType),
        externalId,
        notificationType,
        externalOauthInfo,
        order,
        fileType: this.setFileType(approverType, approvers[i].fileType),
        reportTypes: this.setReportType(approverType, approvers[i].reportTypes),
        // for mat chip
        contactEmail: new UntypedFormControl('', [Validators.pattern(PATTERNS.Email)]),
        contactEmails: this.setContactEmailControl(approvers[i].approvalType, (approvers[i].emails) ? approvers[i].emails.split(',') : [])
      });
      if (clientId) {
        formGroup.addControl('clientId', clientId);
      }
      if (approverId) {
        formGroup.addControl('approverId', approverId);
      }

      this.updateEmailsFromContactEmails(formGroup);

      const approvalType = new UntypedFormControl({
        value: approvers[i].approvalType !== null &&
          approvers[i].approvalType !== undefined ? approvers[i].approvalType : ApprovalType.User,
        disabled: !this.isAdmin
      });

      this.setVariableControls(approvalType, formGroup);
      approvalForm.push(formGroup);
    }
    this.approvalForm = this.updateApproverFormArray(approvalForm);
    this.approvalFormEmitter.emit(this.approvalForm);
  }

  private updateForm() {
    this.approvalForm.markAsDirty();
    this.approvalFormEmitter.emit(this.approvalForm);
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.contactEmails.length > 0) {
      this.emailInputActive = true;
    } else {
      this.emailInputActive = false;
    }
  }

  private updateEmailsFromContactEmails(formGroup: FormGroup): void {
    formGroup.controls.contactEmails.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((emails: string[]) => {
        const invalidEmails = emails.filter((e) => !PATTERNS.Email.test(e.trim()));
        if (invalidEmails.length) {
          formGroup.controls.contactEmails.setErrors({
            email: true
          });
        } else {
          formGroup.get('emails').setValue(emails.join(','));
        }
      });

    formGroup.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.updateForm());
  }
}
