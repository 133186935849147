export class UserViewModel {
    username?: string;
    email?: string;
    companyId?: string;
    userId?: string;
    creationDate?: string;
    currency?: string;
    billedRate?: number;
    otBilledRate?: number;

    constructor(user?: any) {
        if (user) {
            if (user.username) {
                this.username = user.username;
            }
            if (user.email) {
                this.email = user.email;
            }
            if (user.companyId) {
                this.companyId = user.companyId;
            }
            if (user.userId) {
                this.userId = user.userId;
            }
            if (user.otBilledRate) {
                this.otBilledRate = user.otBilledRate;
            }
        }
    }
}

