import dayjs from 'dayjs';
import { skipWhile, withLatestFrom, filter, map, concatMap, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Inject,
  DestroyRef
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormArray,
  UntypedFormControl,
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  FormGroup,
  FormControl,
  FormArray
} from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { Store } from '@ngrx/store';
import { Client, ClientSettings, Contact, DataLoadSettings, TimesheetApprovalSettings, TimesheetReminderSettings } from '../../../models/Client';
import { Observable, combineLatest, from, of } from 'rxjs';
import { Project, ProjectType } from '../../../models/project';
import { TimeEntry } from '../../../models/timeentry';
import { TaxType, UserSettings } from '../../../models/user-settings';
import { DownloadTimeSheetExampleAction, EditClientAction, OpenDeleteClientDialogAction } from '../../../redux/actions/client';
import {
  getGSettingsGlobalSettingsModel,
  State,
  getSelectedClient,
  getProjectsList,
  getTimeEntries,
  getFtuxProvidersState,
  getDashboardSelectedDateRange,
  getInvoiceDataSources,
  getClientTimeSheetTemplateStatus,
  getTimeSheetExampleStatus,
  getCustomFieldsSchemas,
  getCustomFieldsEntries,
} from '../../../redux/reducers/index';
import { getClientLoading } from '../../../redux/reducers';
import { ResponsiveService } from '../../../services/responsive.service';
import { updateProjects, updateEarnings } from 'app/clients/client-shared-functions';
import { filterCurrentWeekTasks, sortInvoiceDueDate } from 'app/shared/helpers';
import { environment } from 'environments/environment';
import { OpenAddTeamDialogAction } from 'app/redux/actions/project';
import { OpenGenerateFixedRateInvoiceDialog } from 'app/redux/actions/invoice';
import { ExternalInvoicingSystems, SuccessStatus, UserCompanyRole } from '@cartwheel/web-components';
import { CartwheelSelectOptions } from '@cartwheel/web-components';
import { InvoiceDataSource } from 'app/models/invoice-data-source';
import { PATTERNS } from 'app/shared/consts';
import { Params, Router } from '@angular/router';
import { CustomFieldEntity, CustomFieldEntryModel, CustomFieldSchemaModel, CustomFieldEntryForm, FieldChanges, UpdatedField, DeletedField, AddedField } from 'app/models/custom-fields';
import { CustomFieldActions } from 'app/redux/actions/customfields';
import { InvoiceFrequency, TimeUnit, CustomProcessFrequencyType, Status, ClientStatus, DayOfWeek, TimeOfDay, TimeRounding } from 'app/shared/enums';

@Component({
  selector: 'app-enterprise-client-card',
  templateUrl: './enterprise-client-card.component.html',
  styleUrls: ['./enterprise-client-card.component.scss']
})
export class EnterpriseClientCardComponent implements OnInit {
  @Input() onDashBoard: boolean;
  @Input() currentRole: UserCompanyRole;
  @Output() dialogStatus: EventEmitter<boolean> = new EventEmitter<boolean>();

  getGlobalSettingsModel$: Observable<UserSettings>;
  getGlobalSettingsModel = new UserSettings();
  getCustomClientFieldsSchemas$: Observable<CustomFieldSchemaModel[]>;
  getCustomClientFieldsSchemas: CustomFieldSchemaModel[] = [];
  getCustomClientFieldsEntries$: Observable<CustomFieldEntryModel[]>;
  getCustomClientFieldsEntries: CustomFieldEntryModel[] = [];

  public customClientFields: FormArray<FormGroup<CustomFieldEntryForm>> = new FormArray<FormGroup<CustomFieldEntryForm>>([]);

  removable = true;
  public approvalForm: UntypedFormGroup;
  public projects: Array<Project>;
  public entries: TimeEntry[];
  public editClient: UntypedFormGroup;
  public isMobile: boolean;
  public loading$: Observable<boolean | SuccessStatus>;
  public clientTimeSheetStatus$: Observable<SuccessStatus>;
  public timeSheetExampleStatus$: Observable<SuccessStatus>;
  public timerIsRunning$: Observable<boolean>;
  public error$: Observable<string>;
  public token$: Observable<string>;
  public cartwheelProviderStatus$: Observable<boolean>;
  public isCustomInvoice: boolean = false;
  public shouldInitializedAddressLine: boolean = false;

  public availableTabs: string[] = [
    'detailsTab',
    'invoicingTab',
    'dataPopulationTab',
    'projectsTab'
  ];
  public showDetailsTab: boolean = true;
  public showTimesheetAutomationTab: boolean = false;
  public showInvoicingTab: boolean = false;
  public showDataPopulationTab: boolean = false;
  public showProjectsTab: boolean = false;

  public allowTimesheetApprovals: boolean = true;
  public activeSubTab: number = 0;
  public selectedTabLabel: 'Details' | 'Timesheet Automation' | 'Invoicing' | 'Data Population' = 'Details';
  public selectedClient$: Observable<Client>;
  public selectedClient: Client = new Client();
  public selectedClientCustomFieldEntries: CustomFieldEntryModel[] = [];
  public averageWeeklyHours: number;
  public numOfActiveProjects: number;
  public getClientLoading$: Observable<boolean | SuccessStatus>;
  private timeEntries$: Observable<TimeEntry[]>;
  private projects$: Observable<Project[]>;
  public userForm: UntypedFormGroup = new UntypedFormGroup({});
  public projectType = ProjectType;
  public panelOpenState = false;
  public error = '';
  public token = '';
  public itemCardTitles: string[] = ['Earnings This Week', 'Active Projects', 'Hours Worked This Week'];
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public addOnBlur = true;
  public clientStatus = ClientStatus;
  public successStatus = SuccessStatus;
  public invoiceDueInterval: CartwheelSelectOptions<number>;
  public env = environment;
  public filteredProjects: Project[];
  public taxTypes: TaxType[];
  public taxTypeOptions: CartwheelSelectOptions<string | null> = [];
  public hasNoDataForClientSettings: boolean = false;
  public dummyInvoiceData = [
    {
      invoiceNumber: '1',
      hours: '30',
      amountBilled: '3000',
      status: 'Ok'
    },
    {
      invoiceNumber: '2',
      hours: '3',
      amountBilled: '5000',
      status: 'Ok'
    }
  ];
  public timeRounding: CartwheelSelectOptions<TimeRounding> = [
    {
      label: 'Round to the nearest minute',
      value: TimeRounding.ToNearestMinute
    },
    {
      label: 'Round to the nearest quarter hour',
      value: TimeRounding.ToNearestQuarterHour
    },
    {
      label: 'Round to the nearest half hour',
      value: TimeRounding.ToNearestHalfHour
    },
    {
      label: 'Round to the nearest hour',
      value: TimeRounding.ToNearestHour
    },
    {
      label: 'Round up to the nearest minute',
      value: TimeRounding.UpToNearestMinute
    },
    {
      label: 'Round up to the nearest quarter hour',
      value: TimeRounding.UpToNearestQuarterHour
    },
    {
      label: 'Round up to the nearest half hour',
      value: TimeRounding.UpToNearestHalfHour
    },
    {
      label: 'Round up to the nearest hour',
      value: TimeRounding.UpToNearestHour
    },
    {
      label: 'Round down to the nearest minute',
      value: TimeRounding.DownToNearestMinute
    },
    {
      label: 'Round down to the nearest quarter hour',
      value: TimeRounding.DownToNearestQuarterHour
    },
    {
      label: 'Round down to the nearest half hour',
      value: TimeRounding.DownToNearestHalfHour
    },
    {
      label: 'Round down to the nearest hour',
      value: TimeRounding.DownToNearestHour
    }
  ];

  public reportDay: CartwheelSelectOptions<DayOfWeek> = [
    {
      label: 'Monday',
      value: DayOfWeek.Monday
    },
    {
      label: 'Tuesday',
      value: DayOfWeek.Tuesday
    },
    {
      label: 'Wednesday',
      value: DayOfWeek.Wednesday
    },
    {
      label: 'Thursday',
      value: DayOfWeek.Thursday
    },
    {
      label: 'Friday',
      value: DayOfWeek.Friday
    },
    {
      label: 'Saturday',
      value: DayOfWeek.Saturday
    },
    {
      label: 'Sunday',
      value: DayOfWeek.Sunday
    }
  ];

  public sendInvoiceInterval: CartwheelSelectOptions<InvoiceFrequency> = [
    {
      label: 'Bi-weekly on the 1st and 15th',
      value: InvoiceFrequency.BiWeeklyOnTheFirstAndFifteenth
    },
    {
      label: 'At the end of the month',
      value: InvoiceFrequency.AtTheEndOfTheMonth
    },
    {
      label: 'Every Friday',
      value: InvoiceFrequency.EveryFriday
    },
    {
      label: 'Custom',
      value: InvoiceFrequency.Custom
    }
  ];

  public customInvoiceInterval: CartwheelSelectOptions<TimeUnit> = [
    {
      label: 'Month',
      value: TimeUnit.Month
    },
    {
      label: 'Week',
      value: TimeUnit.Week
    },
    {
      label: 'Day',
      value: TimeUnit.Day
    }
  ];

  public reminderFrequencyTypes: CartwheelSelectOptions<CustomProcessFrequencyType> = [
    {
      label: 'Months',
      value: CustomProcessFrequencyType.Months,
    },
    {
      label: 'Weeks',
      value: CustomProcessFrequencyType.Weeks,
    },
    {
      label: 'Days (minimum: 1)',
      value: CustomProcessFrequencyType.Days,
    }
  ]

  public timeOfDay: CartwheelSelectOptions<TimeOfDay> = [
    {
      label: '7 am',
      value: TimeOfDay.SevenAM
    },
    {
      label: '8 am',
      value: TimeOfDay.EightAM
    },
    {
      label: '9 am',
      value: TimeOfDay.NineAM
    },
    {
      label: '10 am',
      value: TimeOfDay.TenAM
    },
    {
      label: '2 pm',
      value: TimeOfDay.TwoPM
    },
    {
      label: '4 pm',
      value: TimeOfDay.FourPM
    }
  ];

  private hasInvoiceDataSources: boolean = false;

  constructor(
    @Inject(ChangeDetectorRef) private ref: ChangeDetectorRef,
    @Inject(Store) private store: Store<State>,
    private responsiveService: ResponsiveService,
    private router: Router,
    private destroyRef: DestroyRef,
    @Inject(UntypedFormBuilder) private fb: UntypedFormBuilder
  ) {
    this.invoiceDueInterval = sortInvoiceDueDate();
  }

  public get clientSettingsForm(): UntypedFormGroup {
    return this.editClient.get('clientSettings.dataLoadSettings') as UntypedFormGroup;
  }

  public get lookbackFrequencyForm(): UntypedFormGroup {
    return this.clientSettingsForm.get('lookbackFrequency') as UntypedFormGroup;
  }

  public get populateResult(): { weekDay: string; duration: string } {
    return !this.processFrequencyForm
      ? { weekDay: '', duration: '' }
      : {
        weekDay: this.reportDay[this.clientSettingsForm.controls.processDayOfWeek.value]?.label,
        duration: this.processFrequencyForm.controls.interval.value > 0 && this.processFrequencyForm.controls.interval.valid
          ? `
              ${this.processFrequencyForm.controls.interval.value}
              ${this.customInvoiceInterval[this.processFrequencyForm.controls.frequencyType.value]?.label}${this.processFrequencyForm.controls.interval.value > 1 ? 's' : ''}
            `
          : ''
      }
  }

  public get primaryContact(): UntypedFormGroup {
    return this.editClient.controls.primaryContact as UntypedFormGroup;
  }

  public get processFrequencyForm(): UntypedFormGroup {
    return this.clientSettingsForm.get('processFrequency') as UntypedFormGroup;
  }

  ngOnInit() {
    this.editClient = this._initEditClientForm();
    this.approvalForm = this._initApproverFormArray();
    this.getClientLoading$ = this.store.select(getClientLoading);
    this.selectedClient$ = this.store.select(getSelectedClient).pipe(skipWhile((sc) => !sc));
    this.getGlobalSettingsModel$ = this.store.select(getGSettingsGlobalSettingsModel);
    this.timeEntries$ = this.store.select(getTimeEntries);
    this.projects$ = this.store.select(getProjectsList);
    this.clientTimeSheetStatus$ = this.store.select(getClientTimeSheetTemplateStatus);
    this.timeSheetExampleStatus$ = this.store.select(getTimeSheetExampleStatus);
    this.cartwheelProviderStatus$ = this.store.select(getFtuxProvidersState)
      .pipe(
        map(state => state[ExternalInvoicingSystems.Cartwheel].connectionStatus === SuccessStatus.Success)
      );
    this.store.select(getInvoiceDataSources)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(s => this.hasInvoiceDataSources = Object.values(s).some((t: InvoiceDataSource) => !!t.connectionStatus));
    this.getCustomClientFieldsSchemas$ = this.store.select(getCustomFieldsSchemas);
    this.getCustomClientFieldsSchemas$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((schemas) => {
        this.getCustomClientFieldsSchemas = schemas.filter(schema => schema.customFieldEntity === CustomFieldEntity.Client);
      });
    this.getCustomClientFieldsEntries$ = this.store.select(getCustomFieldsEntries);
    this.getCustomClientFieldsEntries$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((entries) => {
        this.getCustomClientFieldsEntries = entries;
      });
    this._subscribeToWindowSize();

    this.getGlobalSettingsModel$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((gModel: UserSettings) => {
        this.getGlobalSettingsModel = new UserSettings(gModel);
        this.allowTimesheetApprovals = this.getGlobalSettingsModel.companySettings?.allowTimesheetApprovals ?? true;
        this.taxTypes = this.getGlobalSettingsModel.taxTypes || [];
        this.taxTypeOptions = this.taxTypes?.map((t) => ({
          label: `${t.taxTypeName} ${t.taxPercentage}%`,
          value: t.id
        })) ?? [
            {
              value: null,
              label: 'No Tax Rates configured'
            }
          ];
      });

    combineLatest([this.selectedClient$, this.getCustomClientFieldsSchemas$, this.getCustomClientFieldsEntries$]).pipe(
      debounceTime(100),
      takeUntilDestroyed(this.destroyRef),
      distinctUntilChanged(),
      filter(([sc, cfs, cfe]) => !!sc),
    ).subscribe(([selectedClient, customFieldSchemas, customFieldEntries]) => {
      this.selectedClientCustomFieldEntries = customFieldEntries.filter(entry => entry.entityId === selectedClient.clientID);
      this.customClientFields = this._initCustomFields(customFieldSchemas, this.selectedClientCustomFieldEntries);
    });

    combineLatest([this.selectedClient$, this.timeEntries$, this.projects$]).pipe(
      takeUntilDestroyed(this.destroyRef),
      filter(([sc, e, p]) => !!sc),
      withLatestFrom(this.store.select(getDashboardSelectedDateRange))
    )
      .subscribe(([[selectedClient, timeEntries, projects], selectedDateRange]) => {
        this.filteredProjects = projects.filter(e => e.clientId === selectedClient.clientID);
        if (selectedClient.assignedUsers) {
          this.userForm = new UntypedFormGroup({ assignedUsers: this.fb.array(selectedClient.assignedUsers) });
        }

        this.approvalForm = new UntypedFormGroup({
          approvers: this.fb.array(selectedClient.approvers)
        });

        const filteredEntries = filterCurrentWeekTasks(
          selectedDateRange as [Date, Date],
          timeEntries.filter((timeEntry) => {
            return timeEntry && timeEntry.status !== Status.Deleted && timeEntry.clientId === selectedClient.clientID;
          })
        );

        const updatedProjects = updateProjects(
          selectedClient,
          projects.filter((p) => p.clientId === selectedClient.clientID),
          filteredEntries,
          selectedClient.billedRate,
          false
        );
        const [updatedSelectedClient, averageWeeklyHours] = updateEarnings(selectedClient, filteredEntries);

        this.selectedClient = updatedSelectedClient;
        this.averageWeeklyHours = Number(averageWeeklyHours.toFixed(3));
        this.numOfActiveProjects = updatedProjects.length;
        this.shouldInitializedAddressLine = true;

        this._updateFormFromSelectedClient(this.selectedClient);
        this.ref.markForCheck();
      });

    if (this.allowTimesheetApprovals) {
      this.availableTabs.splice(1, 0, 'timesheetAutomationTab');
    }
  }

  public updateForm(userForm: UntypedFormGroup) {
    this.userForm = userForm;
  }

  public updateApprovalForm(approvalForm: UntypedFormGroup) {
    this.approvalForm = approvalForm;
  }

  public clickNewProjectButton() {
    this.store.dispatch(OpenAddTeamDialogAction());
  }

  public clickGenerateInvoiceButton(project: Project) {
    this.store.dispatch(OpenGenerateFixedRateInvoiceDialog({ project: project }));
  }

  private _initEditClientForm(): UntypedFormGroup {
    const form = this.fb.group({
      reminderInterval: [
        this.selectedClient && this.selectedClient.clientSettings?.timesheetReminderSettings?.interval
          ? this.selectedClient.clientSettings?.timesheetReminderSettings?.interval : null,
      ],
      reminderFrequencyType: [
        this.selectedClient && this.selectedClient.clientSettings?.timesheetReminderSettings?.reminderFrequencyType
          ? this.selectedClient.clientSettings?.timesheetReminderSettings?.reminderFrequencyType : null,
      ],
      sendReminders: [
        this.selectedClient && this.selectedClient.clientSettings?.timesheetApprovalSettings?.sendReminders
          ? this.selectedClient.clientSettings?.timesheetApprovalSettings?.sendReminders : null
      ],
      clientName: [
        this.selectedClient && this.selectedClient.clientName ? this.selectedClient.clientName : null,
        [Validators.required, Validators.minLength(1)]
      ],
      billedRate: [
        this.selectedClient && this.selectedClient.billedRate ? this.selectedClient.billedRate : 0,
        [Validators.required, Validators.pattern(/[0-9]+/)]
      ],
      overtimeThreshold: [
        this.selectedClient && this.selectedClient.overtimeThreshold ? this.selectedClient.overtimeThreshold : null,
        [Validators.min(0)]
      ],
      timeRounding: [
        this.selectedClient && this.selectedClient.timeRounding ? this.selectedClient.timeRounding : null,
        [Validators.required]
      ],
      reportDay: [
        this.selectedClient && this.selectedClient.reportDay ? this.selectedClient.reportDay : null,
        [Validators.required]
      ],
      timeOfDay: [
        this.selectedClient && this.selectedClient.timeOfDay ? this.selectedClient.timeOfDay : null,
        [Validators.required]
      ],
      invoiceFrequency: [
        this.selectedClient && this.selectedClient.invoiceFrequency ? this.selectedClient.invoiceFrequency : null,
        [Validators.required]
      ],
      sendClientInvoice: [this.selectedClient && this.selectedClient.invoiceEmails.length > 0 ? true : false],
      sendUserInvoices: [this.selectedClient && this.selectedClient.userInvoiceEmails.length > 0 ? true : false],
      invoiceDueInterval: [
        this.selectedClient &&
          this.selectedClient.invoiceDueInterval !== null &&
          this.selectedClient.invoiceDueInterval !== undefined
          ? this.selectedClient.invoiceDueInterval
          : '',
        this.selectedClient.invoiceDueInterval !== null && this.selectedClient.invoiceDueInterval !== undefined
          ? [this.invoiceDueRequiredValidator]
          : []
      ],
      taxRate: [this.selectedClient?.taxRate?.id ?? null],
      invoiceTerms: [
        this.selectedClient && this.selectedClient.invoiceTerms ? this.selectedClient.invoiceTerms : null,
        []
      ],
      customInvoiceStartAt: [this.selectedClient.customInvoiceFrequency?.invoiceStart ?? dayjs().toDate()],
      customInvoiceIntervalUnit: [this.selectedClient.customInvoiceFrequency?.frequencyType ?? TimeUnit.Week],
      customInvoiceInterval: [this.selectedClient.customInvoiceFrequency?.interval ?? 1, [Validators.pattern(PATTERNS.Number)]],
      invoicePrefix: [
        this.selectedClient?.invoicePrefix ?? null
      ],
      maxReminders: [this.selectedClient?.maxReminders ?? null, [Validators.pattern(PATTERNS.Number), Validators.min(1)]],
      sendInvoiceReminders: [!!this.selectedClient?.sendInvoiceReminders],
      primaryContact: this._initPrimaryContactFormFields(),
      address1: [this.selectedClient.address1],
      address2: [this.selectedClient.address2],
      sendInvoiceOnDuedate: [!!this.selectedClient?.sendInvoiceOnDueDate],
      clientSettings: this._initClientSettingsForm()
    });
    form.setValidators(this._reminderIntervalValidator());
    form.get('sendClientInvoice').valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(sendInvoice => {
        const selectedInvoiceDueIntervalNumber = this.selectedClient.invoiceDueInterval ?? 0;
        const selectedValue = this.invoiceDueInterval[selectedInvoiceDueIntervalNumber]?.value ?? null;
        if (sendInvoice) {
          form.setControl(
            'invoiceEmails',
            new UntypedFormControl(this.selectedClient.invoiceEmails?.length ? [...this.selectedClient.invoiceEmails] : [],
              Validators.required
            )
          );
          form.setControl(
            'invoiceDueInterval',
            new UntypedFormControl(
              selectedValue,
              [this.invoiceDueRequiredValidator]
            )
          );

          form
            .get('invoiceEmails')
            .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((emails: string[]) => {
              const invalidEmails = emails.filter((e) => !PATTERNS.Email.test(e.trim()));
              if (invalidEmails.length) {
                form.get('invoiceEmails').setErrors({
                  email: true
                });
              }
            });
        } else {
          form.removeControl('invoiceEmails');
          form.removeControl('invoiceDueInterval');
        }
      });

    form
      .get('sendUserInvoices')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((sendInvoice) => {
        if (sendInvoice) {
          form.setControl(
            'userInvoiceEmails',
            new UntypedFormControl(
              this.selectedClient.userInvoiceEmails?.length ? this.selectedClient.userInvoiceEmails : [],
              [Validators.required]
            )
          );
          form
            .get('userInvoiceEmails')
            .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((emails: string[]) => {
              const invalidEmails = emails.filter((e) => !PATTERNS.Email.test(e.trim()));
              if (invalidEmails.length) {
                form.get('userInvoiceEmails').setErrors({
                  email: true
                });
              }
            });
        } else {
          form.removeControl('userInvoiceEmails');
        }
      });

    form
      .get('invoiceFrequency')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((invoiceFrequency) => {
        this.isCustomInvoice = Number(invoiceFrequency) === InvoiceFrequency.Custom;

        if (this.isCustomInvoice) {
          form.controls.customInvoiceInterval.setValidators([Validators.required, Validators.pattern(PATTERNS.Number)]);
          form.updateValueAndValidity({ emitEvent: false });
        } else {
          form.controls.customInvoiceInterval.setValidators([]);
          form.updateValueAndValidity({ emitEvent: false });
        }
      });

    form
      .get('sendInvoiceReminders')
      .valueChanges.pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((s) => !s)
      )
      .subscribe(s => form.controls.maxReminders.setValue(null))
    return form;
  }

  public add(event: MatChipInputEvent, invoiceEmails: AbstractControl): void {
    const { chipInput, value } = event;
    const currentEmailControl = new UntypedFormControl(value, [Validators.required, Validators.email]);
    if ((value || '').trim() && !currentEmailControl.errors) {
      const existingInvoiceEmails: string[] = invoiceEmails.value.concat(value.trim());
      invoiceEmails.setValue(existingInvoiceEmails);
      invoiceEmails.markAsDirty();
      chipInput.clear();
    }
  }

  public close() {
    if (this.isMobile) {
      this.dialogStatus.emit(true);
    }
  }

  public remove(index: number, invoiceEmails: AbstractControl): void {
    if (index >= 0) {
      const existingInvoiceEmails = [...invoiceEmails.value.slice(0, index), ...invoiceEmails.value.slice(index + 1)];
      invoiceEmails.setValue(existingInvoiceEmails);
      invoiceEmails.markAsDirty();
    }
  }

  public handleAddressChange({ address1, address2 }: { address1: string; address2: string }): void {
    this.editClient.get('address1').setValue(address1);
    this.editClient.get('address2').setValue(address2);
    this.shouldInitializedAddressLine = false;
    this.editClient.markAsDirty();
    this.editClient.markAsTouched();
  }

  public isActiveTab(tab: string): boolean {
    return this.availableTabs.indexOf(tab) === this.activeSubTab
  }

  public save() {
    const clientEmailControl = this.editClient.get('invoiceEmails');
    const usersEmailControl = this.editClient.get('userInvoiceEmails');
    const sendClientInvoiceControl = this.editClient.get('sendClientInvoice');
    const sendUserInvoicesControl = this.editClient.get('sendUserInvoices');
    var primaryContact = this.editClient.get('primaryContact');
    const client = new Client({
      ...this.selectedClient,
      assignedUsers: this.userForm.get('assignedUsers').value,
      approvers: this.approvalForm.get('approvers').value,
      clientSettings: this.editClient.get('clientSettings').value,
      clientName: this.editClient.get('clientName').value,
      billedRate: this.editClient.get('billedRate').value,
      timeRounding: this.editClient.get('timeRounding').value,
      overtimeThreshold: this.editClient.get('overtimeThreshold').value,
      invoiceEmails: sendClientInvoiceControl.value ? clientEmailControl.value : [],
      userInvoiceEmails: sendUserInvoicesControl.value ? usersEmailControl.value : [],
      timeOfDay: this.editClient.get('timeOfDay').value,
      invoiceFrequency: this.editClient.get('invoiceFrequency').value,
      reportDay: this.editClient.get('reportDay').value,
      invoiceDueInterval: this.editClient.get('invoiceDueInterval')?.value,
      taxRate: this.taxTypes.find((s) => s.id === this.editClient.get('taxRate').value) ?? null,
      invoiceTerms: this.editClient.get('invoiceTerms').value,
      invoicePrefix: this.editClient.get('invoicePrefix').value,
      maxReminders: this.editClient.get('maxReminders').value,
      sendInvoiceReminders: this.editClient.get('sendInvoiceReminders').value,
      address1: this.editClient.get('address1').value,
      address2: this.editClient.get('address2').value,
      customInvoiceFrequency: {
        interval: this.editClient.get('customInvoiceInterval').value,
        frequencyType: this.editClient.get('customInvoiceIntervalUnit').value,
        invoiceStart: this.editClient.get('customInvoiceStartAt').value
      },
      sendInvoiceOnDuedate: this.editClient.get('sendInvoiceOnDuedate').value
    });
    const firstName = primaryContact.get('firstName');
    const lastName = primaryContact.get('lastName');
    const contactEmail = primaryContact.get('email');
    if (primaryContact.valid && firstName.value !== null && firstName.value !== '') {
      primaryContact.get('companyId').setValue(this.currentRole.companyId);
      client.primaryContact = primaryContact.value;
      if (client.primaryContact?.id == null || client.primaryContact?.id == '') {
        delete client.primaryContact.id;
      }
    }
    if (this.hasNoDataForClientSettings || !this.processFrequencyForm.controls.interval.value || !this.lookbackFrequencyForm.controls.interval.value) {
      delete client.clientSettings;
    }
    if (this.allowTimesheetApprovals) {
      const reminderFrequencyType = this.editClient.get('reminderFrequencyType').value;
      const interval = this.editClient.get('reminderInterval').value;

      const reminderFrequencyTypeIsNull = reminderFrequencyType === null;
      const intervalIsNull = interval === null;

      if (!reminderFrequencyTypeIsNull && !intervalIsNull) {
        const timesheetReminderSettings: TimesheetReminderSettings = new TimesheetReminderSettings({
          reminderFrequencyType, interval
        });

        if (!client.clientSettings) {
          client.clientSettings = new ClientSettings({});
        }
        client.clientSettings.timesheetReminderSettings = timesheetReminderSettings;
      }
    }
    const sendReminders = this.editClient.get('sendReminders').value;
    if (sendReminders) {
      const timesheetApprovalSettings: TimesheetApprovalSettings = new TimesheetApprovalSettings({
        sendReminders
      });

      if (!client.clientSettings) {
        client.clientSettings = new ClientSettings();
      }
      client.clientSettings = Object.assign(new ClientSettings(client.clientSettings), { ...timesheetApprovalSettings });
    }


    this.store.dispatch(new EditClientAction(client));
    this.shouldInitializedAddressLine = true;

    const { addedFields, updatedFields, deletedFields } = this._getAddedUpdatedDeletedFields();

    const addSubscription = from(addedFields).pipe(
      concatMap(({ entryToAdd, originalEntry }) => {
        return of(this.store.dispatch(CustomFieldActions.createCustomFieldEntry({ entryToAdd, originalEntry })));
      })
    ).subscribe(() => addSubscription.unsubscribe());

    const updateSubscription = from(updatedFields).pipe(
      concatMap(({ entryToUpdate, originalEntry }) => {
        return of(this.store.dispatch(CustomFieldActions.updateCustomFieldEntry({ entryToUpdate, originalEntry })));
      })
    ).subscribe(() => updateSubscription.unsubscribe());

    const deleteSubscription = from(deletedFields).pipe(
      concatMap(({ entryToDelete, originalEntry, defaultFieldValue }) => {
        return of(this.store.dispatch(CustomFieldActions.deleteCustomFieldEntry({ entryToDelete, originalEntry, defaultFieldValue })));
      })
    ).subscribe(() => deleteSubscription.unsubscribe());
  }

  public showDeleteClientDialog() {
    this.store.dispatch(new OpenDeleteClientDialogAction(this.selectedClient));
  }

  public tabChanged({ index, tab }): void {
    this.activeSubTab = index;
    this.selectedTabLabel = tab.textLabel as 'Details' | 'Timesheet Automation' | 'Invoicing' | 'Data Population';
  }

  public isFormsInvalid(): boolean {
    return (
      this.userForm.invalid ||
      this.approvalForm.invalid ||
      this.editClient.invalid ||
      this.customClientFields.invalid ||
      (this.userForm.pristine && this.approvalForm.pristine && this.editClient.pristine && this.customClientFields.pristine)
    );
  }

  public onClickDataSetup(): void {
    this.close();
    this.goTo('/Team/Settings', { code: 1 })
  }

  public goTo(link: string, queryParams?: Params): void {
    this.router.navigate([link], { queryParams });
  }

  public downloadExample() {
    this.store.dispatch(DownloadTimeSheetExampleAction({ clientId: this.selectedClient.clientID }))
  }

  private _buildAddedField(
    fieldName: string,
    fieldValue: string,
    customFieldEntity: CustomFieldEntity,
    entityId: string,
    originalEntry: CustomFieldEntryModel
  ): AddedField {
    const entryToAdd = { fieldName, fieldValue, customFieldEntity, entityId };

    return { entryToAdd, originalEntry };
  }

  private _buildDeletedField(
    fieldName: string,
    customFieldEntity: CustomFieldEntity,
    entityId: string,
    originalEntry: CustomFieldEntryModel,
    schema: CustomFieldSchemaModel,
  ): DeletedField {
    const entryToDelete = { fieldName, customFieldEntity, entityId };

    return { entryToDelete, originalEntry, defaultFieldValue: schema.defaultFieldValue };
  }

  private _buildUpdatedField(
    fieldName: string,
    fieldValue: string,
    customFieldEntity: CustomFieldEntity,
    entityId: string,
    originalEntry: CustomFieldEntryModel
  ): UpdatedField {
    const entryToUpdate = { fieldName, fieldValue, customFieldEntity, entityId };

    return { entryToUpdate, originalEntry };
  }

  private _getAddedUpdatedDeletedFields(): FieldChanges {
    const previousEntriesMap = new Map(this.selectedClientCustomFieldEntries.map(entry => [entry.fieldName, entry]));
    const schemaMap = new Map(this.getCustomClientFieldsSchemas.map(schema => [schema.fieldName, schema]));

    const result: FieldChanges = {
      addedFields: [],
      updatedFields: [],
      deletedFields: [],
    };

    this.customClientFields.controls.forEach(formGroup => {
      Object.entries(formGroup.controls).forEach(([fieldName, current]) => {
        this._processField(fieldName, current.value, previousEntriesMap, schemaMap, result);
      });
    });

    return result;
  }

  private _initApproverFormArray(): UntypedFormGroup {
    return new UntypedFormGroup({
      approvers: new UntypedFormArray([])
    });
  }

  private _initCustomFields(schemas: CustomFieldSchemaModel[], entries: CustomFieldEntryModel[]): FormArray<FormGroup<CustomFieldEntryForm>> {
    const customFields = schemas.map(schema => {
      const entry = entries.find(entry => entry.fieldName === schema.fieldName);
      return new FormGroup<CustomFieldEntryForm>({
        [schema.fieldName]: new FormControl<string | null>(entry ? entry.fieldValue : schema.defaultFieldValue || null)
      });
    });

    return new FormArray<FormGroup<CustomFieldEntryForm>>(customFields);
  }

  private _initPrimaryContactFormFields(): UntypedFormGroup {
    const form = this.fb.group({
      firstName: [this.selectedClient?.primaryContact?.firstName || null, []],
      lastName: this.selectedClient?.primaryContact?.lastName || null,
      email: [this.selectedClient?.primaryContact?.email || null, [Validators.email]],
      id: [this.selectedClient?.primaryContact?.id || null],
      companyId: ''
    });

    form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((s: Contact) => {
      if ((s.email || s.firstName || s.lastName) && !form.controls.firstName.hasValidator(Validators.required)) {
        ['firstName', 'lastName', 'email'].map((s) => {
          form.controls[s].addValidators(Validators.required);
          form.controls[s].updateValueAndValidity({ emitEvent: false });
        });
      } else if (!s.email && !s.firstName && !s.lastName) {
        ['firstName', 'lastName', 'email'].map((s) => {
          form.controls[s].removeValidators(Validators.required);
          form.controls[s].updateValueAndValidity({ emitEvent: false });
        });
      }
    });
    return form;
  }

  private _initClientSettingsForm(): UntypedFormGroup {
    const form = this.fb.group({
      dataLoadSettings: this.fb.group({
        processDayOfWeek: new UntypedFormControl(this.selectedClient.clientSettings?.dataLoadSettings?.processDayOfWeek ?? null),
        processFrequency: this.fb.group({
          interval: [this.selectedClient.clientSettings?.dataLoadSettings?.processFrequency.interval ?? null, [Validators.pattern(PATTERNS.Number), Validators.min(1)]],
          frequencyType: this.selectedClient.clientSettings?.dataLoadSettings?.processFrequency.frequencyType ?? null,
          processStart: this.selectedClient.clientSettings?.dataLoadSettings?.processFrequency.processStart ?? new Date()
        }),
        lookbackFrequency: this.fb.group({
          interval: [this.selectedClient.clientSettings?.dataLoadSettings?.lookbackFrequency.interval ?? null, [Validators.pattern(PATTERNS.Number), Validators.min(1)]],
          frequencyType: this.selectedClient.clientSettings?.dataLoadSettings?.lookbackFrequency.frequencyType ?? null,
          processStart: this.selectedClient.clientSettings?.dataLoadSettings?.lookbackFrequency.processStart ?? new Date(),
        }),
        lastDataLoadDate: { value: this.selectedClient.clientSettings?.dataLoadSettings?.lastDataLoadDate ?? null, disabled: true }
      })
    });

    form.get('dataLoadSettings.processFrequency.frequencyType').valueChanges.subscribe(s => {
      if (s === TimeUnit.Day) {
        form.get('dataLoadSettings.processFrequency.interval').setValidators([Validators.pattern(PATTERNS.Number), Validators.min(7)])
        form.get('dataLoadSettings.processFrequency.interval').updateValueAndValidity({ emitEvent: false });
      } else {
        form.get('dataLoadSettings.processFrequency.interval').setValidators([Validators.pattern(PATTERNS.Number), Validators.min(1)])
        form.get('dataLoadSettings.processFrequency.interval').updateValueAndValidity({ emitEvent: false });
      }
    })
    form.get('dataLoadSettings.lookbackFrequency.frequencyType').valueChanges.subscribe(s => {
      if (s === TimeUnit.Day) {
        form.get('dataLoadSettings.lookbackFrequency.interval').setValidators([Validators.pattern(PATTERNS.Number), Validators.min(7)])
        form.get('dataLoadSettings.lookbackFrequency.interval').updateValueAndValidity({ emitEvent: false });
      } else {
        form.get('dataLoadSettings.lookbackFrequency.interval').setValidators([Validators.pattern(PATTERNS.Number), Validators.min(1)])
        form.get('dataLoadSettings.lookbackFrequency.interval').updateValueAndValidity({ emitEvent: false });
      }
    })
    return form;
  }

  private invoiceDueRequiredValidator(formControl: AbstractControl): ValidationErrors {
    if (!formControl.parent) {
      return null;
    }

    if (formControl.parent.get('invoiceEmails')?.value && formControl.parent.get('invoiceEmails')?.value > 0) {
      return Validators.required(formControl);
    }
    return null;
  }

  private _processField(
    fieldName: string,
    currentValue: string,
    previousEntriesMap: Map<string, CustomFieldEntryModel>,
    schemaMap: Map<string, CustomFieldSchemaModel>,
    result: FieldChanges
  ): void {
    const previous = previousEntriesMap.get(fieldName);
    const schema = schemaMap.get(fieldName);
    const customFieldEntity = CustomFieldEntity.Client;
    const entityId = this.selectedClient.clientID;

    const isChanged = previous ? previous.fieldValue !== currentValue : schema.defaultFieldValue !== currentValue;

    if (!isChanged) return;

    if (previous && schema) {
      const wasDefault = previous.isDefaultValue;
      const isNowDefault = currentValue !== '' ? schema.defaultFieldValue === currentValue : schema.defaultFieldValue === null;

      if (wasDefault && !isNowDefault) {
        result.addedFields.push(this._buildAddedField(fieldName, currentValue, customFieldEntity, entityId, previous));
      } else if (!wasDefault && !isNowDefault) {
        result.updatedFields.push(this._buildUpdatedField(fieldName, currentValue, customFieldEntity, entityId, previous));
      } else if (!wasDefault && isNowDefault) {
        result.deletedFields.push(this._buildDeletedField(fieldName, customFieldEntity, entityId, previous, schema));
      }
    } else {
      result.addedFields.push(this._buildAddedField(fieldName, currentValue, customFieldEntity, entityId, previous));
    }
  }

  private _reminderIntervalValidator(): ValidatorFn {
    return (group: UntypedFormGroup): ValidationErrors => {
      const intervalControl = group.controls['reminderInterval'];
      const typeControl = group.controls['reminderFrequencyType'];

      const isIntervalNull = intervalControl.value === null;
      const isTypeNull = typeControl.value === null;

      if (!isIntervalNull && isTypeNull) {
        typeControl.setErrors({ pair: 'Unable to save without interval value' })
      } else if (isIntervalNull && !isTypeNull) {
        intervalControl.setErrors({ pair: 'Unable to save without type value' })
      } else if (!isIntervalNull && !isTypeNull) {
        if (intervalControl.value < 1) {
          intervalControl.setErrors({ min: 1 })
        } else {
          intervalControl.setErrors(null)
        }
      } else {
        intervalControl.setErrors(null)
      }

      return;
    }
  };

  private _subscribeToWindowSize(): void {
    this.responsiveService.getMobileStatus()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(status => {
        this.isMobile = status;
      });
  }

  private _updateClientSettingsFromSelectedClient(client: Client): void {
    if (client.clientSettings?.dataLoadSettings) {
      this.hasNoDataForClientSettings = false;
      this.editClient.get('clientSettings.dataLoadSettings').patchValue(new DataLoadSettings(client.clientSettings.dataLoadSettings));
    } else if (this.getGlobalSettingsModel.companySettings?.defaultClientSettings?.dataLoadSettings) {
      this.hasNoDataForClientSettings = false;
      this.editClient.get('clientSettings.dataLoadSettings').patchValue(new DataLoadSettings(this.getGlobalSettingsModel.companySettings.defaultClientSettings.dataLoadSettings));
    } else if (this.hasInvoiceDataSources) {
      this.hasNoDataForClientSettings = false;
      this.editClient.get('clientSettings.dataLoadSettings').patchValue({
        processDayOfWeek: DayOfWeek.Friday,
        processFrequency: {
          interval: 1,
          frequencyType: TimeUnit.Week,
          processStart: new Date()
        },
        lookbackFrequency: {
          interval: 1,
          frequencyType: TimeUnit.Week,
          processStart: new Date()
        },
        lastDataLoadDate: null
      });
    } else {
      this.hasNoDataForClientSettings = true;
      this.editClient.get('reminderInterval').patchValue(null);
      this.editClient.get('reminderFrequencyType').patchValue(null);
    }

    if (client.clientSettings?.timesheetApprovalSettings) {
      this.editClient.get('sendReminders').patchValue(client.clientSettings?.timesheetApprovalSettings.sendReminders);
    } else {
      this.editClient.get('sendReminders').patchValue(null);
    }

    if (this.allowTimesheetApprovals && client.clientSettings?.timesheetReminderSettings) {
      const { interval, reminderFrequencyType } = client.clientSettings.timesheetReminderSettings;

      const reminderFrequencyTypeIsNull = reminderFrequencyType === null;
      const intervalIsNull = interval === null;

      if (!reminderFrequencyTypeIsNull && !intervalIsNull) {
        this.hasNoDataForClientSettings = false;

        this.editClient.get('reminderInterval').patchValue(interval);
        this.editClient.get('reminderFrequencyType').patchValue(reminderFrequencyType);
      }
    }
  }

  private _updateFormFromSelectedClient(selectedClient: Client): void {
    const clientNameField = this.editClient.get('clientName');
    clientNameField.setValue(selectedClient.clientName);
    const billedRateField = this.editClient.get('billedRate');
    billedRateField.setValue(selectedClient.billedRate);
    const overtimeThresholdField = this.editClient.get('overtimeThreshold');
    overtimeThresholdField.setValue(selectedClient.overtimeThreshold);
    const timeRoundingField = this.editClient.get('timeRounding');
    timeRoundingField.setValue(selectedClient.timeRounding);
    const sendUserInvoicesField = this.editClient.get('sendUserInvoices');
    sendUserInvoicesField.setValue((selectedClient.userInvoiceEmails && selectedClient.userInvoiceEmails.length > 0) ? true : false);
    const sendClientInvoiceField = this.editClient.get('sendClientInvoice');
    sendClientInvoiceField.setValue((selectedClient.invoiceEmails && selectedClient.invoiceEmails.length > 0) ? true : false);
    const timeOfDayField = this.editClient.get('timeOfDay');
    timeOfDayField.setValue(selectedClient.timeOfDay);
    const invoiceFrequencyField = this.editClient.get('invoiceFrequency');
    invoiceFrequencyField.setValue(selectedClient.invoiceFrequency);
    const reportDayField = this.editClient.get('reportDay');
    reportDayField.setValue(selectedClient.reportDay);
    const invoiceDueInterval = this.editClient.get('invoiceDueInterval');
    if (invoiceDueInterval !== null && selectedClient.invoiceDueInterval !== null && selectedClient.invoiceDueInterval !== undefined) {
      invoiceDueInterval.setValue(selectedClient.invoiceDueInterval);
    }
    if (selectedClient.invoiceTerms) {
      this.editClient.get('invoiceTerms').setValue(selectedClient.invoiceTerms);
    }
    this.editClient.get('invoicePrefix').setValue(selectedClient.invoicePrefix || '');
    this.editClient.get('maxReminders').setValue(selectedClient.maxReminders || null);
    this.editClient.get('sendInvoiceReminders').setValue(!!selectedClient.sendInvoiceReminders);
    const taxRate = this.editClient.get('taxRate');
    taxRate.setValue(selectedClient.taxRate?.id ?? null);
    const clientPrimaryContact = this.editClient.get('primaryContact');
    selectedClient.primaryContact
      ? clientPrimaryContact.patchValue(selectedClient.primaryContact)
      : clientPrimaryContact.reset();

    this.editClient.get('address1').setValue(selectedClient.address1);
    this.editClient.get('address2').setValue(selectedClient.address2);

    this.editClient.get('customInvoiceStartAt').setValue(selectedClient.customInvoiceFrequency?.invoiceStart ?? dayjs().toDate());
    this.editClient.get('customInvoiceIntervalUnit').setValue(selectedClient.customInvoiceFrequency?.frequencyType ?? TimeUnit.Week);
    this.editClient.get('customInvoiceInterval').setValue(selectedClient.customInvoiceFrequency?.interval ?? 1);
    this.editClient.get('sendInvoiceOnDuedate').setValue(!!selectedClient.sendInvoiceOnDueDate);

    this._updateClientSettingsFromSelectedClient(selectedClient);

    this.editClient.markAsPristine();
  }
}
