import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[appFileUploader]'
})
export class FileUploaderDirective {
  @Output() fileDropped = new EventEmitter<File>();

  @HostBinding('class.fileover') fileOver: boolean;

  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = false;
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = false;
    const files = event.dataTransfer.files;
    if (files.length) {
      this.fileDropped.emit(files[0]);
    }
  }

}
