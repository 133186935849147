import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Client } from 'app/models/Client';
import { DownloadClientTimeSheetTemplateAction, UploadTimeSheetTemplateAction } from 'app/redux/actions/client';
import { State } from 'app/redux/reducers';
import { SuccessStatus } from 'app/shared/enums';

@Component({
  selector: 'app-client-import-timesheet',
  templateUrl: './client-import-timesheet.component.html',
  styleUrls: ['./client-import-timesheet.component.scss']
})
export class ClientImportTimesheetComponent implements OnChanges {
  @Input() client: Client;
  @Input() status: SuccessStatus;
  @ViewChild('fileInputRef') fileInputRef: ElementRef<HTMLInputElement>;

  SuccessStatus = SuccessStatus;
  allowedFileType: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  statusMessage: string = 'Downloading ...';
  selectedFile: File & {lastModifiedDate?: Date};

  private store = inject(Store<State>);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.client || changes.status?.currentValue === SuccessStatus.Error) {
      this.selectedFile = null;
      if (this.fileInputRef) {
        this.fileInputRef.nativeElement.value = '';
      }
    }
  }

  fileDropped(file: File): void {
    if (file?.size >0 &&  this.status !== SuccessStatus.IsLoading) {
      this.statusMessage = 'Uploading ...';
      this.selectedFile = file;
      this.store.dispatch(UploadTimeSheetTemplateAction({clientId: this.client.clientID, file: file}));
    }
  }

  downloadTemplate(): void {
    if (this.status !== SuccessStatus.IsLoading) {
      this.statusMessage = 'Downloading ...';
      this.store.dispatch(DownloadClientTimeSheetTemplateAction({clientId: this.client.clientID}))
    }
  }

  onChange(event: Event): void {
    const file = (event.currentTarget as HTMLInputElement).files[0];
    this.fileDropped(file);
  }
}
