<div class="add-client-container">
  <form #dialog>
    <div class="dialog-body">
      <div class="dialog-header">
        <span class="dialog-header-text">Add Client</span>
        <cartwheel-icon-button matIconName="clear" class="dialog-close-btn" (onClick)="closeDialog()" />
      </div>

      <div class="dialog-content">
        <mat-stepper
          labelPosition="bottom"
          [orientation]="isMobile ? 'vertical' : 'horizontal'"
          [linear]="isLinear"
          #stepper
        >
          <ng-template matStepperIcon="edit" let-index="index" let-active="active">
            @if (index === stepper.steps.length - 1) {
              <mat-icon>done</mat-icon>
            } @else {
              {{ index + 1 }}
            }
          </ng-template>

          <mat-step [stepControl]="firstFormGroup">
            <form [formGroup]="clientInfo">
              <ng-template matStepLabel>Details</ng-template>
              <div class="grid gap-20 pb-20">
                <div>
                  <cartwheel-input
                    class="grid grid-cols-1"
                    label="CLIENT NAME"
                    [inputFormControl]="clientInfo.get('clientName') | formControlType"
                  />
                  @if (clientInfo.get('clientName').errors?.required && clientInfo.touched) {
                    <span class="text-danger text-12">Please enter client name</span>
                  }
                </div>

                <div [formGroup]="primaryContactForm">
                  <label class="font-bold text-middleGray text-12 leading-18">PRIMARY CONTACT</label>

                  <div class="p-20 mt-10 border border-solid rounded border-lightGray">

                    <div class="grid grid-cols-1 gap-20 mb-20 phone:grid-cols-2">
                      <cartwheel-input label="FIRST NAME" [inputFormControl]="primaryContactForm.get('firstName') | formControlType" />
                      <cartwheel-input label="LAST NAME" [inputFormControl]="primaryContactForm.get('lastName') | formControlType" />
                    </div>
                    <cartwheel-input class="mb-10" label="EMAIL" [inputFormControl]="primaryContactForm.get('email') | formControlType" />

                    @if (
                      primaryContactForm.controls.firstName.errors?.required ||
                      primaryContactForm.controls.lastName.errors?.required ||
                      primaryContactForm.controls.email.errors?.required
                    ) {
                      <div class="text-danger text-12">
                        All Primary Contact fields must be completed.
                      </div>
                    }
                    @if (primaryContactForm.controls.email.errors?.email) {
                      <div class="text-danger text-12">
                        Please enter a valid email address.
                      </div>
                    }
                  </div>
                </div>

                <app-address-autocomplete
                  label="Client Address"
                  placeholder="Enter client address"
                  class="block pb-20"
                  [shouldInitialized]="shouldInitializeAddressLine"
                  [addressGroup]="{
                    address1: clientInfo.controls.address1.value,
                    address2: clientInfo.controls.address2.value
                  }"
                  (onAddressChange)="handleAddressChange($event)"
                />

                <div class="grid grid-cols-2 gap-10">
                  <cartwheel-input
                    type="number"
                    label="BILLED RATE"
                    data-cy="billed-rate"
                    [inputFormControl]="clientInfo.get('billedRate') | formControlType"
                  />
                  <div class="flex items-end">
                    <cartwheel-select
                      class="w-full"
                      [options]="billedRateOptions"
                      [selectFormControl]="clientInfo.get('billedOn') | formControlType"
                    />
                  </div>
                </div>

                <div class="grid items-end grid-cols-2 gap-20">
                  @for (customClientFieldForm of customClientFields.controls; track 'form-group' + $index) {
                    <form [formGroup]="customClientFieldForm">
                      @for (control of customClientFieldForm.controls | keyvalue; track 'form-control' + $index) {
                        <cartwheel-input 
                          class="block"
                          type="text"
                          [label]="control.key"
                          [inputFormControl]="control.value | formControlType"
                        />
                      }
                    </form>
                  }
                </div>

                <cartwheel-select
                  [options]="timeRoundingOptions"
                  label="Time Rounding"
                  [selectFormControl]="clientInfo.get('timeRounding') | formControlType"
                />
                <app-add-users [isMobile]="isMobile" [newClient]="true" (userFormEmitter)="updateForm($event)" />
              </div>

              <div class="flex items-center gap-5 mt-20">
                <cartwheel-button
                  class="[&>button]:px-40"
                  label="Next"
                  data-cy="nextBtn"
                  color="secondary"
                  (onClick)="onNext(stepper)"
                  [status]="
                    clientInfo.invalid || !usersForm || usersForm.invalid || (primaryContactForm.status === 'INVALID' && primaryContactForm.touched)
                      ? successStatus.Disabled
                      : successStatus.Enabled
                  "
                />
                <cartwheel-button
                  label="Cancel"
                  type="text-button"
                  color="middleGray"
                  (onClick)="closeDialog()"
                />
              </div>
            </form>
          </mat-step>

          @if (allowTimesheetApprovals || isMobile) {
            <mat-step [stepControl]="secondFormGroup">
              <ng-template matStepLabel>Automation</ng-template>
  
              <app-client-approvers
                [isMobile]="isMobile"
                [isAdmin]="true"
                [isEnterprise]="true"
                [approvalForm]="approvalForm"
                (approvalFormEmitter)="updateApprovalForm($event)"
              />
  
              @if (!isMobile) {
                <form [formGroup]="timesheetApprovalSettingsForm">
                  <mat-checkbox color="primary" labelPosition="before" formControlName="sendReminders">
                    <span class="font-bold text-middleGray text-12 leading-18">
                      SEND REMINDERS?
                    </span>
                  </mat-checkbox>
                </form>
                <form [formGroup]="timesheetReminderSettingsForm">
                  @if (allowTimesheetApprovals && timesheetApprovalSettingsForm.get('sendReminders').value) {
                    <div class="mt-10">
                      <div class="mb-4 font-bold text-middleGray text-12 leading-18">
                        TIMESHEET APPROVAL REMINDER INTERVAL
                      </div>
                      <div class="grid grid-cols-2 gap-x-10">
                        <cartwheel-input
                          class="w-full"
                          type="number"
                          label="Interval number"
                          [inputFormControl]="timesheetReminderSettingsForm.get('interval') | formControlType"
                        />
                        <cartwheel-select
                          class="w-full"
                          label="Interval unit"
                          [options]="reminderFrequencyTypes"
                          [selectFormControl]="timesheetReminderSettingsForm.get('reminderFrequencyType') | formControlType"
                        />
                      </div>
                      @if (timesheetReminderSettingsForm.controls.interval.errors?.min) {
                        <div class="mt-5 text-danger text-12">
                          Please enter an interval
                        </div>
                      }
                      @if (timesheetReminderSettingsForm.controls.interval.errors?.pair) {
                        <div class="mt-5 text-danger text-12">
                          Unable to save without interval value
                        </div>
                      }
                      @if (timesheetReminderSettingsForm.controls.reminderFrequencyType.errors?.pair) {
                        <div class="mt-5 text-danger text-12">
                          Unable to save without type value
                        </div>
                      }
                    </div>
                  }
                </form>
              }

              <div class="flex items-center gap-5 mt-20">
                <cartwheel-button
                  class="[&>button]:px-40"
                  label="Next"
                  data-cy="nextBtn"
                  color="secondary"
                  (onClick)="onNext(stepper)"
                  [status]="!approvalForm || approvalForm.invalid ? successStatus.Disabled : successStatus.Enabled"
                />
                <cartwheel-button
                  label="Previous"
                  type="text-button"
                  color="middleGray"
                  (onClick)="onPrevious(stepper)"
                />
              </div>
            </mat-step>
          }

          <mat-step [stepControl]="thirdFormGroup">
            <div class="thirdStep">
              <form [formGroup]="invoiceForm">
                <ng-template matStepLabel>Report</ng-template>
                <div class="grid gap-y-10">
                  <div class="grid items-end grid-cols-2 gap-x-20">
                    <label class="col-span-2 mb-4 font-medium uppercase text-12 text-darkGray">SEND TIME REPORTS AT</label>

                    <cartwheel-select
                      [options]="reportDay"
                      [selectFormControl]="invoiceForm.get('reportDay') | formControlType"
                    />

                    <cartwheel-select
                      [options]="timeOfDay"
                      [selectFormControl]="invoiceForm.get('timeOfDay') | formControlType"
                    />

                    <p class="col-span-2 mt-10 text-12 text-middleGray">
                      (Dont worry we will remind you before we send them and you can change this later)
                    </p>
                  </div>

                  <div class="flex items-center gap-5 py-10">
                    <label class="font-medium uppercase text-12 text-darkGray">Send Client Invoice?</label>
                    <mat-checkbox color="primary" formControlName="sendClientInvoice" data-cy="sendInvoicecheck"> </mat-checkbox>
                  </div>

                  @if (invoiceForm.contains('invoiceEmails')) {
                    <cartwheel-select
                      label="SEND INVOICES INTERVAL"
                      [options]="invoiceFrequencies"
                      [selectFormControl]="invoiceForm.get('invoiceFrequency') | formControlType"
                    />
                  }

                  @if (isCustomInvoice) {
                    <cartwheel-datepicker
                      label="INVOICE START"
                      [dateFormControl]="invoiceForm.get('customInvoiceStartAt') | formControlType"
                    />

                    <div class="flex flex-col pb-10">
                      <label class="col-span-2 mb-4 font-medium uppercase text-12 text-darkGray">INVOICE INTERVAL</label>

                      <div class="grid items-start grid-cols-2 gap-x-20">
                        <cartwheel-select
                          [options]="customInvoiceInterval"
                          [selectFormControl]="invoiceForm.get('customInvoiceIntervalUnit') | formControlType"
                        />

                        <div>
                          <cartwheel-input
                            [inputFormControl]="invoiceForm.get('customInvoiceInterval') | formControlType"
                          />
                          @if (invoiceForm.controls.customInvoiceInterval.errors?.required) {
                            <span class="text-danger text-12"> Please enter an invoice interval </span>
                          } @else if (invoiceForm.controls.customInvoiceInterval.errors?.pattern) {
                            <span class="text-danger text-12"> Please enter a whole number </span>
                          }
                        </div>
                      </div>
                    </div>
                  }

                  @if (invoiceForm.contains('invoiceEmails')) {
                    <div>
                      <cartwheel-chip-grid
                        label="PLEASE ENTER EMAILS FOR WHO SHOULD RECEIVE INVOICES"
                        data-cy="invoice-email"
                        [chipFormControl]="invoiceForm.get('invoiceEmails') | formControlType"
                      />
                      @if (invoiceForm.get('invoiceEmails').errors?.required) {
                        <span class="text-danger text-12">Please enter email address</span>
                      } @else if (invoiceForm.get('invoiceEmails').errors?.email) {
                        <span class="text-danger text-12">Please enter valid email address</span>
                      }
                    </div>

                    <cartwheel-select
                      label="INVOICE DUE INTERVAL"
                      [options]="invoiceDueInterval"
                      [selectFormControl]="invoiceForm.get('invoiceDueInterval') | formControlType"
                    />

                    <cartwheel-input
                      label="INVOICE TERMS"
                      placeholder="If you have custom terms for this client and their invoice, add them here"
                      [inputFormControl]="invoiceForm.get('invoiceTerms') | formControlType"
                    />

                    <cartwheel-input
                      label="INVOICE PREFIX"
                      placeholder="If you have custom invoice prefix for this client and their invoice, add them here"
                      [inputFormControl]="invoiceForm.get('invoicePrefix') | formControlType"
                    />
                  }

                  @if (env.feature.invoicing.allowUserInvoice) {
                    <div class="flex items-center gap-x-5">
                      <label class="uppercase">Send User Invoice?</label>
                      <mat-checkbox color="primary" formControlName="sendUserInvoice"> </mat-checkbox>
                    </div>

                    @if (invoiceForm.contains('userInvoiceEmails')) {
                      <cartwheel-chip-grid [chipFormControl]="invoiceForm.get('userInvoiceEmails') | formControlType" />
                    }
                  }
                </div>

                @if (!isMobile) {
                  <div class="py-20 -mx-48">
                    <hr />
                  </div>
                }

                <div class="flex items-center gap-5 mt-20">
                  <cartwheel-button
                    class="[&>button]:px-40"
                    label="Done"
                    data-cy="done"
                    color="secondary"
                    (onClick)="saveClient()"
                    [status]="
                      invoiceForm.invalid || clientInfo.invalid || customClientFields.invalid ? successStatus.Disabled : successStatus.Enabled
                    "
                  />
                  <cartwheel-button
                    label="Previous"
                    data-cy="previous"
                    type="text-button"
                    color="middleGray"
                    (onClick)="onPrevious(stepper)"
                  />
                </div>
              </form>
            </div>
          </mat-step>
        </mat-stepper>
      </div>
    </div>
  </form>
</div>
