
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-enterprise-client-card-dialog',
  templateUrl: './enterprise-client-card-dialog.component.html',
  styleUrls: ['./enterprise-client-card-dialog.component.scss']
})
export class EnterpriseClientCardDialogComponent {
  constructor(
    private dialog: MatDialogRef<EnterpriseClientCardDialogComponent>,
  ) { }

  public closeDialog() {
    this.dialog.close();
  }

  public handleDialogStatus($event) {
    if ($event) {
      this.closeDialog();
    }
  }

}
