<div class="assign-users-container">
  <form [formGroup]="usersForm">
    <div class="flex flex-col mb-30">
      <div class="font-medium text-14 text-middleGray mb-15">Assign Users</div>
      <div class="flex justify-between">
        <cartwheel-button class="[&>button]:px-40" label="Add User" (onClick)="addNewBlankUser()" />

        @if (totalPages > 1) {
          <div class="flex items-center justify-between gap-x-5">
            <cartwheel-button
              class="[&>*]:text-middleGray [&>*]:border-middleGray"
              label="Prev"
              type="outline-button"
              color="lightGray"
              size="sm"
              [status]="currentPage === 0 ? successStatus.Disabled : successStatus.Enabled"
              (onClick)="prevPage()"
            />
            <cartwheel-button
              class="[&>*]:text-middleGray [&>*]:border-middleGray"
              label="Next"
              type="outline-button"
              color="lightGray"
              size="sm"
              [status]="currentPage === totalPages - 1 ? successStatus.Disabled : successStatus.Enabled"
              (onClick)="nextPage()"
            />
          </div>
        }
      </div>
    </div>

    <div formArrayName="users" class="grid gap-y-20" data-cy="users-list">
      @for (user of userControls; track 'user-form-control' + $index) {
        <div>
          <div class="grid items-end grid-cols-10 gap-10" [formGroupName]="$index + currentPage * perPage">
            <cartwheel-select
              [ngClass]="{
                'col-span-7': !(allowOvertimeRate$ | async),
                'col-span-5': allowOvertimeRate$ | async
              }"
              label="User Name"
              [options]="userListOptions"
              [disabled]="!user.get('editable').value"
              [selectFormControl]="user.get('username') | formControlType"
            />

            <cartwheel-input
              class="col-span-2"
              label="Billed Rate"
              type="number"
              [inputFormControl]="user.get('billedRate') | formControlType"
            />
            @if (allowOvertimeRate$ | async) {
              <cartwheel-input
                class="col-span-2"
                label="O/T Billed Rate"
                type="number"
                [inputFormControl]="user.get('otBilledRate') | formControlType"
              />
            }
            <cartwheel-icon-button
              class="col-span-1 pb-2"
              matIconName="delete"
              size="sm"
              color="danger"
              (onClick)="removeUser($index)"
            />
          </div>
          @if (user.get('username').errors?.required) {
            <span class="text-danger text-12">Please Select a user</span>
          }
        </div>
      }
    </div>
  </form>
</div>
