<div class="block w-full h-auto bg-white rounded-md shadow-card leading-1 text-14 p-23">
  <div class="flex items-start gap-10 pb-26">
    <div class="relative flex w-full pr-35">
      <div
        class="items-center flex-1 overflow-x-hidden font-medium text-darkerGray text-24 text-ellipsis whitespace-nowrap leading-30"
      >
        {{ selectedClient?.clientName }}
      </div>
      @if (selectedClient?.clientName) {
        <cartwheel-icon-button
          class="absolute top-0 right-0"
          matIconName="delete"
          type="outline-button"
          color="danger"
          size="sm"
          (onClick)="showDeleteClientDialog()"
        />
      }
    </div>
    @if (isMobile) {
      <cartwheel-icon-button matIconName="close" color="darkGray" size="sm" (onClick)="close()" />
    }
  </div>
  <div>
    <app-earnings-summary
      [earnings]="selectedClient?.monthEarnings"
      [billedHours]="numOfActiveProjects"
      [numberOfTasks]="averageWeeklyHours"
      [titles]="itemCardTitles"
      parentName="client-card"
      [globalSettingsModel]="getGlobalSettingsModel"
    />

    <mat-tab-group class="card-tabs mt-13" disableRipple [selectedIndex]="activeSubTab" (selectedTabChange)="tabChanged($event)">
      <mat-tab label="Details" />
      @if (allowTimesheetApprovals) {
        <mat-tab label="Timesheet Automation">
          <ng-template mat-tab-label>
            Timesheet <br />
            Automation
          </ng-template>
        </mat-tab>
      }
      <mat-tab label="Invoicing" />
      <mat-tab label="Data Population" />
    </mat-tab-group>
  </div>

  <form class="py-23" [formGroup]="editClient">
    @switch (selectedTabLabel) {
      @case ('Details') {
        <div class="grid grid-cols-1 gap-20">
          <cartwheel-input label="Client Name" [inputFormControl]="editClient.get('clientName') | formControlType" />
          @if (getGlobalSettingsModel.companySettings?.clientsCanHavePrimaryContacts) {
            <div class="grid grid-cols-2 gap-20" [formGroup]="primaryContact">
              <cartwheel-input
                label="Primary contact first name"
                [inputFormControl]="primaryContact.get('firstName') | formControlType"
              />
              <cartwheel-input
                label="Primary contact last name"
                [inputFormControl]="primaryContact.get('lastName') | formControlType"
              />
              <cartwheel-input
                class="col-span-2"
                label="Primary contact email"
                [inputFormControl]="primaryContact.get('email') | formControlType"
              />
            </div>
          }

          <app-address-autocomplete
            label="Client Address"
            placeholder="Enter client address"
            [shouldInitialized]="shouldInitializedAddressLine"
            [addressGroup]="{
              address1: editClient.controls.address1.value,
              address2: editClient.controls.address2.value
            }"
            (onAddressChange)="handleAddressChange($event)"
          />

          <cartwheel-select
            label="Time Tracking Rounding Rules"
            [options]="timeRounding"
            [selectFormControl]="editClient.get('timeRounding') | formControlType"
          />

          <div class="grid items-end grid-cols-2 gap-20">
            <cartwheel-input
              label="BILLED RATE"
              type="number"
              [inputFormControl]="editClient.get('billedRate') | formControlType"
            />
            <cartwheel-input placeholder="task hourly rate" [disabled]="true" value="" />
          </div>

          <div class="grid items-end grid-cols-2 gap-20">
            <cartwheel-input
              label="OVERTIME THRESHOLD"
              type="number"
              [inputFormControl]="editClient.get('overtimeThreshold') | formControlType"
            />
            <cartwheel-input placeholder="hours" [disabled]="true" value="" />
          </div>

          <div class="grid items-end grid-cols-2 gap-20">
            @for (customClientFieldForm of customClientFields.controls; track 'form-group' + $index) {
              <div [formGroup]="customClientFieldForm">
                @for (control of customClientFieldForm.controls | keyvalue; track 'form-control' + $index) {
                  <cartwheel-input 
                    class="block"
                    type="text"
                    [label]="control.key"
                    [inputFormControl]="control.value | formControlType"
                  />
                }
              </div>
            }
          </div>

          <app-add-users [assignedUsers]="selectedClient.assignedUsers" (userFormEmitter)="updateForm($event)" />
        </div>
      }
      @case ('Timesheet Automation') {
        <app-client-approvers
          [selectedClient]="selectedClient"
          [isMobile]="isMobile"
          [isEnterprise]="true"
          [approvalForm]="approvalForm"
          [isAdmin]="true"
          (approvalFormEmitter)="updateApprovalForm($event)"
          (saveClient)="save()"
        />

        <mat-checkbox color="primary" labelPosition="before" formControlName="sendReminders">
          <span class="font-bold text-middleGray text-12 leading-18">
            SEND REMINDERS?
          </span>
        </mat-checkbox>

        @if (allowTimesheetApprovals && editClient.get('sendReminders').value) {
          <div class="mt-10">
            <div class="mb-4 font-bold text-middleGray text-12 leading-18">
              TIMESHEET APPROVAL REMINDER INTERVAL
            </div>
            <div class="grid grid-cols-2 gap-x-10">
              <cartwheel-input
                class="w-full"
                type="number"
                label="Interval number"
                [inputFormControl]="editClient.get('reminderInterval') | formControlType"
              />
              <cartwheel-select
                class="w-full"
                label="Interval unit"
                [options]="reminderFrequencyTypes"
                [selectFormControl]="editClient.get('reminderFrequencyType') | formControlType"
              />
            </div>
            @if (editClient.controls.reminderInterval.errors?.min) {
              <div class="mt-5 text-danger text-12">
                Please enter an interval
              </div>
            }
            @if (editClient.controls.reminderInterval.errors?.pair) {
              <div class="mt-5 text-danger text-12">
                Unable to save without interval value
              </div>
            }
            @if (editClient.controls.reminderFrequencyType.errors?.pair) {
              <div class="mt-5 text-danger text-12">
                Unable to save without type value
              </div>
            }
          </div>
        }
        <div class="mt-25 mb-60">
          <div class="mb-4 font-medium uppercase text-12 text-darkGray">TIMESHEET TEMPLATE</div>
          <app-client-import-timesheet
            class="block mx-15"
            [status]="clientTimeSheetStatus$ | async"
            [client]="selectedClient"
          />
        </div>
        <cartwheel-button
          statusButton
          class="w-full mr-20"
          type="raised-button"
          label="Download Example"
          color="primary"
          [status]="timeSheetExampleStatus$ | async"
          (onClick)="downloadExample()" />
      }
      @case ('Invoicing') {
        <div class="grid grid-cols-1 gap-20">
          <div class="grid items-end grid-cols-2 gap-20">
            <cartwheel-select
              label="SEND TIME REPORTS AT"
              [options]="reportDay"
              [selectFormControl]="editClient.get('reportDay') | formControlType"
            />
            <cartwheel-select
              [options]="timeOfDay"
              [selectFormControl]="editClient.get('timeOfDay') | formControlType"
            />
          </div>

          <div class="flex items-center gap-5 py-10">
            <label class="font-medium uppercase text-12 text-darkGray">Send Client Invoice?</label>
            <mat-checkbox color="primary" formControlName="sendClientInvoice" />
          </div>

          @if (editClient.contains('invoiceEmails')) {
            <div>
              <cartwheel-chip-grid
                label="Please enter emails for who should receive invoices"
                [chipFormControl]="editClient.get('invoiceEmails') | formControlType"
              />

              @if (editClient.get('invoiceEmails').errors?.required) {
                <span class="text-danger text-12">Please enter email address</span>
              } @else if (editClient.get('invoiceEmails').errors?.email) {
                <span class="text-danger text-12">Please enter valid email address</span>
              }
            </div>

            <cartwheel-select
              label="SEND INVOICE INTERVAL"
              [options]="sendInvoiceInterval"
              [selectFormControl]="editClient.get('invoiceFrequency') | formControlType"
            />

            @if (isCustomInvoice) {
              <cartwheel-datepicker
                label="INVOICE START"
                [dateFormControl]="editClient.get('customInvoiceStartAt') | formControlType"
              />

              <div class="flex flex-col pb-10">
                <label class="mb-4 font-medium uppercase text-12 text-darkGray">INVOICE INTERVAL</label>

                <div class="grid items-start grid-cols-2 gap-20">
                  <cartwheel-select
                    [options]="customInvoiceInterval"
                    [selectFormControl]="editClient.get('customInvoiceIntervalUnit') | formControlType"
                  />
                  <div>
                    <cartwheel-input [inputFormControl]="editClient.get('customInvoiceInterval') | formControlType" />
                    @if (editClient.controls.customInvoiceInterval.errors?.required) {
                      <span class="text-danger text-12">Please enter an invoice interval</span>
                    } @else if (editClient.controls.customInvoiceInterval.errors?.pattern) {
                      <span class="text-danger text-12">Please enter a whole number</span>
                    }
                  </div>
                </div>
              </div>
            }

            <cartwheel-select
              label="INVOICE DUE INTERVAL"
              [options]="invoiceDueInterval"
              [selectFormControl]="editClient.get('invoiceDueInterval') | formControlType"
            />
            <cartwheel-select
              label="TAX RATE"
              [selectFormControl]="editClient.get('taxRate') | formControlType"
              [options]="taxTypeOptions"
            />
            <cartwheel-input
              label="INVOICE TERMS"
              [inputFormControl]="editClient.get('invoiceTerms') | formControlType"
            />
            <cartwheel-input
              label="INVOICE PREFIX"
              [inputFormControl]="editClient.get('invoicePrefix') | formControlType"
            />

            <div class="flex items-center gap-5">
              <label class="font-medium uppercase text-12 text-darkGray">SEND INVOICE REMINDERS?</label>
              <mat-checkbox color="primary" formControlName="sendInvoiceReminders"> </mat-checkbox>
            </div>

            @if (editClient.controls.sendInvoiceReminders.value) {
              <cartwheel-input
                label="MAX REMINDERS"
                [inputFormControl]="editClient.get('maxReminders') | formControlType"
              />
            }

            <div class="flex items-center gap-5">
              <label class="font-medium uppercase text-12 text-darkGray">
                SHOULD INVOICE REMINDERS BE SENT ON THE INVOICE DUE DATE?
              </label>
              <mat-checkbox color="primary" formControlName="sendInvoiceOnDuedate"> </mat-checkbox>
            </div>
          }

          @if (env.feature.invoicing.allowUserInvoice) {
            <div class="flex items-center gap-5 py-10">
              <label class="font-medium uppercase text-12 text-darkGray">SEND USER INVOICES?</label>
              <mat-checkbox color="primary" formControlName="sendUserInvoices"> </mat-checkbox>
            </div>

            <div>
              <cartwheel-chip-grid [chipFormControl]="editClient.get('userInvoiceEmails') | formControlType" />

              @if (editClient.get('userInvoiceEmails').errors?.required) {
                <span class="text-danger text-12">Please enter email address</span>
              } @else if (editClient.get('userInvoiceEmails').errors?.email) {
                <span class="text-danger text-12">Please enter valid email address</span>
              }
            </div>
          }
        </div>
      }
      @case ('Data Population') {
        @if (clientSettingsForm && !hasNoDataForClientSettings) {
          <div class="grid grid-cols-1 gap-20" [formGroup]="clientSettingsForm">
            <div class="flex flex-col">
              <div class="flex items-center gap-5 mb-4">
                <label class="font-medium text-12 text-darkGray">When do you want data to be populated ?</label>
                <mat-icon
                  matTooltip="What day do you want Cartwheel to query your invoice datasource to populate the dashboard ?"
                  matTooltipPosition="above"
                  class="text-darkGray"
                >
                  info_outline
                </mat-icon>
              </div>
              <cartwheel-select
                [options]="reportDay"
                [selectFormControl]="clientSettingsForm.get('processDayOfWeek') | formControlType"
                placeholder="Select day of week"
              />
            </div>

            <div class="flex flex-col" [formGroup]="processFrequencyForm">
              <label class="mb-4 font-medium text-12 text-darkGray"> How often do you want the process to run ? </label>

              <div class="grid items-start grid-cols-2 gap-20">
                <cartwheel-input [inputFormControl]="processFrequencyForm.get('interval') | formControlType" />
                <cartwheel-select
                  [options]="customInvoiceInterval"
                  [selectFormControl]="processFrequencyForm.get('frequencyType') | formControlType"
                  placeholder="Select day of week"
                />
              </div>
            </div>

            <div class="flex flex-col" [formGroup]="lookbackFrequencyForm">
              <label class="mb-4 font-medium text-12 text-darkGray">
                How far do you want the process to look back ?
              </label>

              <div class="grid items-start grid-cols-2 gap-20">
                <cartwheel-input [inputFormControl]="lookbackFrequencyForm.get('interval') | formControlType" />
                <cartwheel-select
                  [options]="customInvoiceInterval"
                  [selectFormControl]="lookbackFrequencyForm.get('frequencyType') | formControlType"
                  placeholder="Select day of week"
                />
              </div>
            </div>

            @if (populateResult.weekDay) {
              <label class="font-medium text-12 text-darkGray">
                The population will occur every Other {{ populateResult.weekDay }} and retrieve
                {{ populateResult.duration }} worth of information
              </label>
            }

            @if (clientSettingsForm.get('lastDataLoadDate').value) {
              <cartwheel-datepicker
                label="Last Data Population"
                [dateFormControl]="clientSettingsForm.get('lastDataLoadDate') | formControlType"
              />
            }
          </div>
        } @else {
          <div class="flex flex-col items-center gap-20 py-65">
            <p class="text-center text-darkGray">
              Uh oh, it looks like your company doesn't have an invoice datasource connected. Please click the button
              below to connect an invoice datasource
            </p>
            <cartwheel-button
              class="[&>button]:px-30"
              label="Setup now"
              color="secondary"
              (onClick)="onClickDataSetup()"
            />
            <img class="w-150" src="assets/carty-sad.png" />
          </div>
        }
      }
    }
  </form>

  @if (onDashBoard) {
    <cartwheel-button
      class="w-150"
      label="Save"
      statusButton
      color="secondary"
      [status]="
        (getClientLoading$ | async) === true || (getClientLoading$ | async) === successStatus.IsLoading
          ? successStatus.IsLoading
          : ((getClientLoading$ | async) === successStatus.Success && successStatus.Success) ||
            ((getClientLoading$ | async) === successStatus.Error && successStatus.Error) ||
            (isFormsInvalid() && successStatus.Disabled) ||
            successStatus.Enabled
      "
      (onClick)="save()"
    />
  }
</div>
